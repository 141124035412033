.profile-page-container {
    height: 100vh;
    max-width: 100vw;
    overflow-y: scroll;
   // background-color: rgba(70, 180, 132, 0.274);
    overflow-x: hidden;
    background-color: #151515bb;
    display: flex;
    justify-content: flex-start;
  z-index: 1;
}

.profile-page-content {
    min-height: 100%;
    width: 90%;
    padding-top: 3.925em;
    padding-left: 10%;
    margin:0;
    align-self: flex-start;
}
.profile-header-container {
    display: flex;
    flex-direction: row;
    width: 92.25%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start; 
    gap: 2em;
 // background-color: rgba(70, 131, 180, 0.274);
}
.profile-image-wrapper {
    min-width: 8em;
    min-height: 8em;
  //  outline:3px solid #eee;
    display: flex;
 // padding-bottom: .125em;
    align-items: center;
   // filter: blur(1px);
  //  background:linear-gradient(45deg, rgba(30, 160, 125, 0.65),#67de7999,#6777de99);
    background-color: rgba(89, 201, 151, 0.591);
    border-radius: 15px;
}
.profile-image-text {
    font-size: clamp(18px, 6vw, 80px);
    color:#eee;
    font-family: 'Rubik';
    font-weight: 400;   
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    margin-left: auto;
    text-shadow: 4px 3px 2px rgba(28, 28, 28, 0.25);
    margin-right: auto;
    transform: translateY(-.0125em);
}

.profile-header-wrapper {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
   // min-height: 7.65em;
    min-height: 100%;
    padding-top: 0.35em;
 // align-self: center;
 // justify-content: center;
 // background-color: rgba(255, 0, 0, 0.223);
}
.profile-header-wrapper-alt {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    min-height:7.75em;
   // min-height: 100%;
   padding-bottom: .25em;
  
   // padding-top: 0.35em;
 // align-self: center;
 // justify-content: center;
  //background-color: rgba(255, 0, 0, 0.223);
}
.profile-campaign-complete-button {
    height: 4%;
    // margin-top: .25em;
    border-radius: 24px;
    width: fit-content;

    margin-left: auto;
    transition: all .15s linear;
    display: flex;
    justify-content: center;
    background-color: #eee;
    margin-right:1.5%;
    //  transform: translateY(-.5em);
    // margin-top: 2%;
    transition: all .15s linear;
    box-shadow: 0 0 0px 0 rgba(60,60,60,0.45);
    align-self:center;
    // margin-bottom: .25em;
}
.profile-campaign-complete-button:hover {
    background-color: #38bda5;
  //  box-shadow: 0 0 0px 6px rgba(94,94,94,0.45);
}
.profile-header-text {
    font-size: clamp(18px, 3vw, 38px);
    font-family: "Inter";
    letter-spacing: -.5px;
    font-weight: 800;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    color:#eee;
    line-height:48px;
}
.profile-header-text-alt {
    font-size: clamp(18px, 3vw, 38px);
    font-family: "Inter";
    letter-spacing: -.5px;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    font-weight: 800;
    color:#eee;
    line-height:64px;
  //  margin-top: auto;
}
.profile-link-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:.375em;
    transform: translateX(-0.15em);
}
.profile-link-container-alt {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
//    background-color: rgba(255, 0, 0, 0.142);
    margin-top: auto;
    gap:.6em;
    transform: translateX(-0.15em);
}
.profile-header-subcontent {
    display: flex;
    gap:.8em;
    justify-content: flex-start;
    align-items: center;

}
.profile-status-container-alt {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
//    background-color: rgba(255, 0, 0, 0.142);
    margin-top: auto;
    gap:.6em;
  //  transform: translateY(-150%);
  transform: translateX(-0.15em);
   
}
.profile-header-subtext {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 34px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.profile-header-subtext-alt {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 28px;
    letter-spacing: -.25px;
    font-weight: 400;

    color: #aaa;
}
.profile-bio-container {
    width: 100%;
    height: fit-content;
    display: flex;
   // background-color: rgba(0, 255, 195, 0.142);
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:.6em;
    //margin-top: auto;
    transform: translateX(-0.15em);
}
.profile-header-bio {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 30px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.link-icon {
    color:#ababab;
    transform: translateY(.0125em);
    width: 1.0em;
    height: 1.0em;
}
.pen-icon {
    color:#ababab;
   // transform: translateY(.0125em);
    width: 1.0em;
    height: 1.0em;
}
.check-icon {
    color:#ababab;
    transform: translateY(.0125em);
    width: .95em;
    height: .95em;
}
.manage-profile-container {
    max-width: 92.25%;
    height: fit-content;
    display: flex;
    padding-top: 2.75em;
   // background-color: rgba(46, 139, 86, 0.422);
}
.manage-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  // padding-right: 2.25em;
  // align-items: center;
  // background-color: rgba(117, 46, 139, 0.422);
    padding-bottom: 1em;
    border-bottom: 1px dashed #5a5a5a;
}
.manage-header-text {
    font-size: clamp(14px, 3vw, 22.5px);
    font-family: "Inter";
    letter-spacing: -.25px;
    font-weight: 600;
    color:#eee;
    line-height:36px;
}
.manage-header-subtext {
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.nickname-container {
    display: flex;
    position: relative;
    max-width: 92.25%;
    padding-top: 1.25em;
    padding-bottom: 2em;
    border-bottom: 1px dashed #5a5a5a;
    min-height: 18vh;
 // border:1px solid #454545;
 // align-items: center;
 // background-color: rgba(218, 165, 32, 0.244);
}
.profile-chart-container {
    display: flex;
    position: relative;
    max-width: 92.25%;
    padding-top: 1.25em;
  //  padding-bottom: .75em;
   // border-bottom: 1px dashed #5a5a5a;
    min-height: 36vh;
 // border:1px solid #454545;
 // align-items: center;
 // background-color: rgba(218, 165, 32, 0.244);
}
.profile-chart-wrapper {
    min-height:100%;
   // background-color: rgba(255, 0, 0, 0.211);
    width: 45%;
   // margin-top: .25em;
    display: flex;
}
.profile-chart-insights-wrapper {
    margin-top: 1.25em;
    width: 100%;
    border-radius: 20px;
   // background-color: rgba(70, 131, 180, 0.27);
   height:83%;

   // height:57.25%
}
.nickname-details-container {
    width: 22.5%;
    height: 100%;
    padding-top: .05em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
 // @function background-color: rgba(255, 192, 203, 0.266);
}
.nickname-details-text {
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color:#fff;
    line-height:36px;
}
.nickname-details-subtext {
    padding-top: .125em;
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 22px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.nickname-input-container {
    min-width: 70%;
    padding-right: .1em;
    min-height: 100%;
    display: flex;
 // padding-top: 1.25em;
    margin-left: auto;
    border-radius: 16px;
    flex-direction: column;
    gap:.75em;
 // background-color: rgba(255, 236, 192, 0.266);
}
.nickname-display-wrapper {
    width: 77.5%;
    height:34.5%;
    margin-top: .825em;
    background-color: #151515;
    outline:1px solid #454545;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius:16px;
    margin-left: auto;
 // margin-right: auto;
}
.nickname-display-input-wrapper {
    width: 77.5%;
    height:34.5%;
    margin-top: .65em;
    background-color: #151515;
    outline:1px solid #454545;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all .15s linear;
    border-radius:16px;
    margin-left: auto;
 // margin-right: auto;
}
.nickname-display-input-wrapper:hover {
    filter: brightness(1.25);
}
.nickname-display-url {
    min-width: 27%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 100%;
    background-color: #252525;
    display: flex;
    padding-left: .5%;
    align-items: center;
    justify-content: center;
}
.nickname-edit-wrapper {
    min-width:92.5%;
    height: 100%;
 // background-color: #353535;
    display: flex;
 // border-radius: 5px;
    border-top-right-radius: 16px;
    align-items: center;
    justify-content: flex-start;
    //padding-left: 3%;
    border-bottom-right-radius: 16px;
}
.nickname-icon-wrapper {
    min-width: 7.5%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 100%;
  // background-color: #252525;
    display: flex;
  // padding-left: .125%;
    align-items: center;
    justify-content: center;
}
.nickname-url-text {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #a0a0a0
}
.nickname-display-container {
    min-width: 67%;
    height: 100%;
 // background-color: #353535;
    display: flex;
 // border-radius: 5px;  
    align-items: center;
    justify-content: flex-start;
    padding-left: 2.5%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.nickname-display-text {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 22px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #eee;
}
.nickname-input-text {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 22px;
    letter-spacing: -.25px;
    font-weight: 400;
    background-color: #151515;
    border-top-right-radius: 16px;
    width:100%;
    //padding-left: 3%;
    height: 100%;
    //background-color: red;
    border:none;
    outline:none;
    border-bottom-right-radius: 16px;
    color: #eee;
}
.nickname-input-text::placeholder {
    color:#6a6a6a;
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 22px;
    letter-spacing: -.25px;
    font-weight: 400;
}
.nickname-edit-icon {
    width: 1.05em;
    height: 1.05em;
    color:#6a6a6a;
    transform: translateX(.075em);
}
.profile-favorites-wrapper {
  //  background-color: red;
    width: 100%;
    padding-top: .5em;
   // min-height:fit-content;
}
.account-container {
    width: 92.25%;
    height: 60vh;
  //  background-color: rgba(0, 139, 139, 0.228);
    display: flex;
  
}
.account-donations-container {
    height: 100%;
    //background-color: rgba(255, 0, 0, 0.211);
    width: 50%;  
    padding-left: .2em;
}

.donation-details-container {
    width: 100%;
    height: fit-content;
    padding-top: .5em;
 //   padding-left: .125em;
    display: flex;
    flex-direction: column;
   // background-color: rgba(255, 192, 203, 0.266);
}

.donation-details-text {
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color:#fff;
    line-height:38px;
}
.donation-details-subtext {
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";// padding-left: .025em;
   // line-height: 20px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}

.account-donations-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: .45em;
    justify-content:center;

  //  background-color: rgba(46, 139, 86, 0.198);
}
.account-donations-list-content {
    width: 88.5%;
    
    height: 95%;
    display: flex;
    flex-direction: column;
 //   background-color: rgba(46, 103, 139, 0.198);
}
.account-friends-list {
    width: 100%;
    height: 100%;
    display: flex;
  //  gap:1em;
    flex-direction: column;
    padding-top: 1em;
  //  background-color: rgba(46, 139, 86, 0.198);
}
.account-friends-list-content {
    width: 88.5%;
    max-height: 95%;
    gap:2.375em;
    padding-top: 1em;
    padding-bottom: .25em;
    overflow-y: hidden;
    align-items: flex-start;
    display: flex;
 // border-bottom: 1px solid #2e2e2e;
    flex-direction: column;
 // background-color: #1e1e1e;
}
.account-friends-container {
    max-height: 100%;
    //background-color: rgba(255, 234, 0, 0.211);
    width: 50%;
    padding-left: 2%;
}
.profile-donation-item {
    display: flex;
    padding-bottom: .1em;
    border-bottom: 1px solid #2e2e2e;
    width: 100%;
    height: 25%;
}
.donation-item-last {
    border-bottom: 1px solid transparent;
}
.profile-donation-item-info {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content:center;
    flex-direction: column;
}
.profile-donation-item-title {
    font-size: clamp(10px, 3vw, 16px);
    color:#eee;
    font-family: 'Inter';
    line-height: 24px;
    font-weight: 600;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.profile-donation-text-wrapper {
    display: flex;
   // padding-top: ;
    align-items: center;
    gap:0.425em;
   // justify-content: center;
}
.profile-clock-icon {
    width: 1em;
    height: 1em;
    transform: scale(0.95) translateY(-.01em);
    color: #a0a0a0;
}
.profile-donation-item-text {
    font-size: clamp(10px, 3vw, 14px);
    color:#a0a0a0;
    font-family: 'Rubik';

    line-height: 22px;
    font-weight: 400;
}
.profile-donation-item-type-wrapper {
    width: fit-content;
    max-height: fit-content;
    padding-left: 2.25%;
    padding-right: 2.25%;
    padding-top: .15em;
    padding-bottom: .15em;
    transform: scale(.925) translateX(-.25em);
    margin-top: 1.875%;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
   // padding-bottom:.3%;
    border-radius:5px;
    
}
.profile-healthcare {
    background-color: rgba(214, 72, 86, 0.7);
}
.profile-human {
    background-color: rgb(53, 108, 153);
}  
.profile-environment {
    background-color: #5abb6f95;
}
.profile-animals {
    background-color: #7abc45a4;
}
.profile-education {
   // background-color: rgba(233, 209, 100, 0.837);
    background-color: rgba(230, 184, 93, 0.8);
    //background-color: rgba(161, 135, 42, 0.837);
}
.profile-research {
    transform: scale(.925) translateX(-.45em);
    background-color: rgb(50, 114, 110);
}
.profile-community {
    background-color: #7c634cee;
}
.profile-donation-item-type-text {
    font-size: clamp(10px, 3vw, 14px);
    color:#eee;
    font-family: 'Rubik';
   // padding-top: 3.5%;
    //padding-bottom: 3.5%;
    line-height: 24px;
    //letter-spacing: -.1px;
    font-weight: 400;
}

.profile-friends-item {
    display: flex;
  
  //  background-color: rgba(255, 0, 0, 0.142);
  // border-radius: 5px;
    align-items: center;
    justify-content: center;
  
 //  padding-bottom: .1em;
 //   border-bottom: 1px solid #2e2e2e;
    width: 100%;
    height: 19.5%;
}
.profile-friends-image-container {
    min-width:12.5%;
    min-height: 100%;
   // border-radius: 2.5em;
   display: flex;
   justify-content: flex-start;
   align-self: center;
   //background-color: #149da07a;
}
.profile-friends-image-wrapper {
    min-width: 3.25em;
    height: 3.25em;
    border-radius: 3.25em;
    align-self: center;
    background-color: #2e2e2e;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-friends-item-info {
    height: 100%;
    width: 100%;
    padding-left: .25em;
    padding-top: .075em;
    display: flex;
   // padding-left: 1%;
    justify-content:center;
   // border-radius: 5px;
   // padding-left: 1em;
    flex-direction: column;
   // background-color: #1a1a1a;
}

.profile-friends-item-title {
    font-size: clamp(10px, 3vw, 16px);
    color:#eee;
    font-family: 'Inter';
    line-height: 22px;
    font-weight: 600;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.profile-friends-text-wrapper {
    display: flex;
    align-items: center;
    gap:0.4em;
    transform: translateX(-.1em);
}
.profile-favorites-container {
    width: 92.25%;
    height: 25vh;
    padding-top: 2.25em;
    padding-bottom: 1.65em;
//    margin-top: 3.25vh;
    display: flex;
    flex-direction: row;
  //  border-bottom: 1px dashed #5a5a5a;
   // justify-content: center;
 //   gap:1.675em;
    //background-color: rgba(221, 160, 221, 0.247);
    //align-items: flex-start;
  }

  .profile-chart-legend {
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    //gap:1em;

    align-self: center;
    
    min-height: 100%;
   // align-self: center;


   // margin-top: .875em;
  //  align-items: flex-end;
    z-index: 1;
    transform: translateX(-1em);
    width: 50%;
  //  padding-bottom: .35em;

  //  background-color: rgba(255, 0, 0, 0.239);
}
.profile-chart-legend-wrapper {
 //   border-top: 1px dashed #454545;
    display: flex;
    flex-direction: column;
    gap:1em;
    width: 85%;
 //   padding-top: 1.5em;
//    padding-bottom: 1em;

  //  background-color: seagreen;
}

.profile-chart-legend-item {
    display: flex;
    justify-content: flex-end;
    gap: 1.65em;
    min-width: 100%;
    //padding-right: 15%;
    
    margin-left: auto;
    height: 5%;
   // background-color: red;
    align-items: center;
    //padding-right: .75em;
}

.profile-chart-legend-color {
    min-width: 1.3em;
    border-radius: 5px;

   // outline: 1px solid #959595;
    min-height: 1.3em;
    outline: 1px dashed #bbb;
    background: rgba(214, 72, 86, 0.8);
}
.profile-chart-legend-color-avg {
    min-width: 1.3em;
    border-radius: 5px;
    outline: 1px dashed #bbb;
   // border:1px dashed rgba(214, 72, 86, 0.6);
   // outline: 1px solid #959595;
    min-height: 1.3em;
  //  background: rgba(214, 72, 86, 0.7);
    background: rgba(64, 64, 64, .8);
}
.profile-chart-hr {
    background: rgba(63, 118, 163, .8),
}
.profile-chart-ev {
    background: rgba(76, 173, 115,.8),
}
.profile-chart-ed {
    background: #f4c567dd,
}
.profile-chart-details-text {
    font-size: clamp(8px, 3vw, 19px);
    font-family: "Inter";
    letter-spacing: -.25px;
    font-weight: 700;
    color:#eee;
   // line-height:22px;
   // transform: translateX(-1em);
    margin-left: auto;
    text-align: right;
    width: 85%;
  //  background-color: red;
// padding-
    padding-right: 15%;

}
.profile-campaign-donate-button {
    min-height: 4%;
   // margin-top: .25em;
    //min-width: 94%;
    border-radius: 24px;
  //  margin-left: auto;
    transition: all .2s linear;
    display: flex;
  //  padding-left: 15%;
    justify-content: center;
    margin-right:1.5%;

  //  transform: translateY(-.5em);
   // margin-top: 2%;
    align-self:center;
   // margin-bottom: .25em;
}
.profile-follow-button {
    min-height: 4%;
   // margin-top: .25em;
    min-width: 100%;
    border-radius: 24px;
  //  margin-left: auto;
    transition: all .2s linear;
    display: flex;
  //  padding-left: 15%;
    justify-content: center;
    margin-right:1.5%;
  //  transform: translateY(-.5em);
   // margin-top: 2%;
    align-self:center;
   // margin-bottom: .25em;
}
.profile-follow-button:hover {
    filter: brightness(1.15);
}
.profile-follow-button-alt {
    height: 4%;
   // margin-top: .25em;
    border-radius: 24px;
    width: 11.5%;
    margin-left: auto;
    transition: all .2s linear;
    display: flex;
    background-color: #eee;
    justify-content: center;
   // margin-right:.5%;
  //  transform: translateY(-.5em);
   // margin-top: 2%;
    align-self:center;
   // margin-bottom: .25em;
}
.profile-campaign-button {
    height: 4%;
   // margin-top: .25em;
    border-radius: 24px;
    width: fit-content;
    padding-left: 2.5%;
    margin-left: auto;
    transition: all .2s linear;
    display: flex;
    justify-content: center;
    background-color: #eee;
    margin-right:1.5%;
  //  transform: translateY(-.5em);
   // margin-top: 2%;
   transition: all .15s linear;
    align-self:center;
   // margin-bottom: .25em;
}
.profile-campaign-button:hover {
    transform: scale(1.05)
}
.profile-campaign-button-alt {
    height: 4%;
   // margin-top: .25em;
    border-radius: 24px;
   // width: 18.25%;
    width: fit-content;
    padding-left: 2.5%;
    margin-left: auto;
    transition: all .2s linear;
    display: flex;
    background-color: #eee;
    justify-content: center;
    margin-right:1%;
  //  transform: translateY(-.5em);
   // margin-top: 2%;
   transition: all .15s linear;
    align-self:center;
   // margin-bottom: .25em;
}
.profile-header-subwrapper {
    height: auto;
    display: flex;
    flex-direction: column;

    gap:1em;
   // margin-top: .25em;
    border-radius: 24px;
  //  margin-left: auto;
  //  background-color: rgba(255, 0, 0, 0.184);
    transition: all .2s linear;
    display: flex;
    justify-content: center;
    margin-right:1.5%;
    margin-left: auto;

  //  transform: translateY(-.5em);
   // padding-top: 1em;
    align-self:center;
   // margin-bottom: .25em;

}
.profile-campaign-button-alt:hover {
    transform: scale(1.05);
}
.follow-active {
    width: 11.75%;
    background-color: #eee;
}
.follow-inactive {
    width: 13%;
    background-color: #252525;
}

.profile-following-icon-alt:hover {
    transform: scale(1.15);
    color:lightcoral;
}
.profile-follow-link-button {
    text-decoration: none;
    min-height: 2.75em;
    width: 100%;
    border-radius: 24px;
    gap:1em;
    padding-left: 10%;
    padding-right: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  //  align-self: center;
}
.profile-support-link-button {
    text-decoration: none;
    min-height: 2.75em;
    width: 100%;
    border-radius: 24px;
    gap:1.075em;
    padding-left: 1.75em;
    padding-right: 1.075em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  //  align-self: center;
}
.profile-following-link-button {
    text-decoration: none;
    min-height: 2.75em;
    width: 100%;
    border-radius: 24px;
    gap:1em;
    padding-left: 1.85em;
    padding-right: 1.125em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  //  align-self: center;
}
.profile-pending-link-button {
    text-decoration: none;
    min-height: 2.75em;
    width: 100%;
    border-radius: 24px;
    gap:1em;
 //   padding-left: 1em;
   // padding-right: 1.125em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  //  align-self: center;
}
.complete-onboard {
    padding-left:1.875em;
}
.profile-campaign-link-button-alt {
    text-decoration: none;
    min-height: 2.75em;
    width: 100%;
    border-radius: 24px;
    gap:.75em;
 //   padding-left: 1em;
    padding-right: 1.25em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  //  align-self: center;
}
.profile-following-link-button-alt {
    text-decoration: none;
    min-height: 2.75em;
    width: 100%;
    border-radius: 24px;
    gap:.75em;
 //   padding-left: 1em;
    padding-right: 1.25em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  //  align-self: center;
}
.profile-follow-text {
    margin-left: auto;
   // margin-right:auto;
    font-size: clamp(10px, 3vw, 17px);
    font-family: "Rubik";
    transform: translateY(.025em);
    font-weight: 00;
    z-index: 1;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    padding-top: .025em;
   // align-self: flex-end;
    background-color: transparent;
    //color:#eee;
    color:#000;
}
.profile-follow-text-alt {
    margin-left: auto;
    // margin-right:auto;
     font-size: clamp(10px, 3vw, 17px);
     font-family: "Rubik";
    // transform: translateY(.025em);
     font-weight: 00;
     user-select: none;
     -webkit-user-select: none; 
     -moz-user-select: none; 
     -ms-user-select: none;
     overflow: hidden;
     z-index: 1;
   //  padding-top: .025em;
    // align-self: flex-end;
     background-color: transparent;
     //color:#eee;
     color:#aaa;
}
.profile-campaign-text-alt {
    margin-left: auto;
    // margin-right:auto;
     font-size: clamp(10px, 3vw, 17px);
     font-family: "Rubik";
    // transform: translateY(.025em);
     font-weight: 400;
     user-select: none;
     -webkit-user-select: none; 
     -moz-user-select: none; 
     -ms-user-select: none;
     overflow: hidden;
     z-index: 1;
   //  padding-top: .025em;
    // align-self: flex-end;
     background-color: transparent;
     //color:#eee;
     color:#aaa;
}
.profile-pending-text-alt {
    margin-left: auto;
    margin-right: auto;
    // margin-right:auto;
     font-size: clamp(10px, 3vw, 17px);
     font-family: "Rubik";
    // transform: translateY(.025em);
     font-weight: 00;
     user-select: none;
     -webkit-user-select: none; 
     -moz-user-select: none; 
     -ms-user-select: none;
     overflow: hidden;
     z-index: 1;
   //  padding-top: .025em;
    // align-self: flex-end;
     background-color: transparent;
     //color:#eee;
     color:#aaa;
}
.profile-follow-icon {
    width: 1.175em;
    height: 1.175em;
    color:#202020;
  //  opacity: 0;
   // display: none;

}
.profile-following-icon {
    width: 1.175em;
    height: 1.175em;
    color:#aaa;
}
.profile-following-support-icon {
    width: 1.05em;
    padding-top: .05em;
    height: 1.05em;
    color:#aaa;
}
.profile-following-icon-alt {
    width: 1.25em;
    height: 1.25em;
    transition: all .15s linear;
    color:#aaa;

}
.profile-accept-follow-icon {
    width: 1.25em;
    height: 1.25em;
    transition: all .15s linear;
    color:#000;
}
.profile-accept-follow-icon:hover {
    color:rgb(231, 80, 80);
}
.profile-page-item-text-alt {
    font-size:clamp(10px,3.25em,26px);
    color:#eee;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    line-height: normal;
    align-self: center;
    font-family: 'Rubik';
  }