@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
p {
    margin: 0;
    z-index: 999;
  }
  
  html {
    width: 100vw;
    height: 100vh;
    padding: 0;  
  }
.catalog-panel-container {
    min-width: 100%;
    min-height: 70vh;


    background-color: #252525;
    border-radius: 16px;
    position: sticky;
    top: 0; // position from the top of the viewport when it starts to stick
    transition: top 0.3s ease-in-out; // ani
    padding-top:3.35vh;
    padding-bottom:3.75vh;
    display: flex;
    flex-direction: column;
}
.category-filter-title {
    color:#eee;
    font-size:clamp(12px, 2.5vw, 14px);
    font-family: 'Inter';
    padding-left: 2vw;
    font-weight: 600;
}

.category-filter-container {
    padding-top:.75vh;
    min-width: 100%;
    padding-left: 2vw;
    display: flex;
    flex-direction: column;


}
.catalog-category-wrapper {
    max-width: 80%;
   // background-color: #4a4a4a;
    min-height: 22px;
    padding-top:1.85vh;
    display: flex;
    justify-content: space-between;
    flex-direction: row;   
    align-items: center;
}
.catalog-category-text {
    color:#bcbcbc;
    line-height: 16px;
    font-size:clamp(12px, 2.5vw, 15px);
    font-family: 'Rubik';
    font-weight: 400;
}
.checkbox {
    width: 16px;
    height: 16px;
    background-color: #4a4a4a;
}
.category-filter-title {
    color:#eee;
    font-size:clamp(12px, 2.5vw, 15px);
    font-family: 'Inter';
    padding-left: 2vw;
    font-weight: 600;
}

.category-filter-title:before,
.category-filter-title:after {
  background-color:#555555;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;

  vertical-align: middle;
  width: 56.5%;
}

.category-filter-title:before {
  right: .8em;
  height:0px;
 
  margin-left: -56.5%;
  
}

.category-filter-title:after {
  left: 1em;
  margin-right: -60%;
}
.middle-filter {
    margin-top:3.25vh;
}
.middle-filter:before,
.middle-filter:after {
  background-color:#555555;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;

  vertical-align: middle;
  width: 67.25%;
}

.middle-filter:before {
  right: .5em;
  height:0px;
  margin-left: -67.25%;
}

.middle-filter:after {
  left: 1em;
  margin-right: -60.5%;
}
.bottom-filter {
    margin-top:3.75vh;
}
.bottom-filter:before,
.bottom-filter:after {
  background-color:#555555;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;

  vertical-align: middle;
  width: 69%;
}

.bottom-filter:before {
  right: .8em;
  height:0px;
 
  margin-left: -69%;
  
}

.bottom-filter:after {
  left: 1.125em;
  margin-right: -70%;
}
.first-category {
    padding-top: 1.25vh;
}
.test-button-container {
  width: 100%;
  min-height: 4vh;
  display: flex;
  background-color: #3a3a3a;
  margin-top:4vh;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  justify-content: center;
}
.test-panel-button {
  outline:none;
  border:transparent;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  min-height: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  align-self: center;
  display: flex;
  min-width: 100%;
}
.test-button-text {
  font-family: 'Rubik';
  font-weight: 400;
  color:#eee;
  font-size:clamp(10px, 2.5vw, 14px)
}


// toggle switch
.toggle-switch-container {
  text-align: center;
  align-self: center;
  justify-self: center;
  transform: rotate(180deg);
 // padding-top:1px;
}
.toggle-switch {
  position: relative;
 // box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.15);
  width: 28px;

  text-align: left;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: none;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: 0;
  transition: all 0.125s ease-in;
}
.inner:before,
.inner:after {
  float: right;
  width: 50%;
  min-height: 14.5px;
  padding: 0;
  line-height: 14.5px;
  color: #fff;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  background: linear-gradient(-315deg, rgba(237, 203, 118, 1),rgba(224, 181, 111, 0.57));
  //background-color: rgba(237, 203, 118, 0.9);
  color: #fff;
}
.inner:after {
  content: "";

  background:linear-gradient(315deg, #bbb, #abababab);
  color: #fff;
  text-align: right;
}
.switch {
  width: 9.25px;
  height: 9.25px;
  margin: 2.5px;
  background: #1a1a1a;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 13.5px;
  border-radius: 20px;
  transition: all 0.125s ease-in;
}
.checkbox:checked + .label .inner {
  margin-left: -100%;
}
.checkbox:checked + .label .switch {
  left: 0px;
}
