.confirmation-page {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin:0;
    display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
}
.confirmation-page-container {
    display: flex;
    flex-direction: column;
    width: 82.5vw;
    max-width: 82.5vw;
    height: 100%;
    padding-top: 5em;
    justify-content: space-between;
    overflow-x: hidden;
    margin-left: auto;
    overflow-y: scroll;
  // background-color: rgba(0, 255, 204, 0.098);
}
.confirmation-header-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-self: center;
    min-width: 100%;
    align-items: center;
    justify-content: center;
}

.confirmation-header-text {
    font-size: clamp(18px, 3vw, 36px);
    font-family: "Inter";
    margin-left: auto;
    margin-right: auto;
    letter-spacing: -.25px;
    font-weight: 800;
    color:#eee;
    line-height:46px;
}
.contact-subtext {
    transition: all .15s linear;
    font-size: clamp(10px, 3vw, 17px);
    font-family: "Rubik";
    margin-left: auto;
    margin-right: auto;
    line-height: 36px;
    padding-top: .2em;
    letter-spacing: -.25px;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    font-weight: 400;
    color: #bababa;
}
.contact-subtext:hover {
    color:#eee;
}
.confirmation-header-subtext {
    font-size: clamp(10px, 3vw, 17px);
    font-family: "Rubik";
    margin-left: auto;
    margin-right: auto;
    line-height: 36px;
    padding-top: .2em;
    letter-spacing: -.25px;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    font-weight: 400;
    color: #959595;

}
.confirmation-footer-container {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
   // gap:.375em;
    justify-content: flex-start;
}
.confirmation-check-container {
    width: 100%;
    display: flex;
   // background-color: red;
    padding-bottom: 1.5em;
    justify-content: center;
    align-items: center;
}
.confirmation-footer-text {
    font-family: "Rubik";
    color: #a0a0a0;
    font-size: clamp(12px, 2vw, 14px);
    font-weight: 400;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
}
.confirmation-check-patch {
    border-radius: 100%;
    filter: contrast(1.05) brightness(1.05);
    min-width:6.3vw;
    min-height: 6.3vw;
    color:rgba(28, 190, 166, 0.825);
    background: -webkit-linear-gradient(135deg,rgba(240, 202, 105, 0.875),rgba(50, 198, 176, 0.875));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   // color: linear-gradient(135deg,rgba(240, 202, 105, 0.875),rgba(50, 198, 176, 0.875));
}
.confirmation-check-patch-alt {
    border-radius: 100%;
    filter: contrast(1.05) brightness(1.05);
    min-width:6.35vw;
    min-height: 6.35vw;
    color:rgb(244, 116, 116,0.825);
    background-color: rgba(246, 100, 100, 0.3);
   // color: linear-gradient(135deg,rgba(240, 202, 105, 0.875),rgba(50, 198, 176, 0.875));
}


.confirmation-page-item-list {
    display: flex;
    width: 76.5%;
    flex-direction: column;
    height: 88.5%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5%;
 //   background-color: #eee;
}
.confirmation-first-item {
    margin-top: 1px;
    display: flex;
    border-top: 1px dashed #3a3a3a;
    border-bottom: 1px dashed #3a3a3a;
    padding-top: .125em;
    width: 100%;
    height: auto;
}
.confirmation-last-item {
    margin-top: 0.5px;
    display: flex;
   // border-bottom: 1px dashed #3a3a3a;
    width: 100%;
    height: auto;
}
.confirmation-page-item {
    display: flex;
   // margin-top: 1px;
    border-top: 1px solid #151515;
    border-bottom: 1px dashed #3a3a3a;
    width: 100%;
    height: auto;
}

.confirmation-page-item-info {
//    max-height: 98.125%;
    width: 50%;
    padding-top: 1%;
    padding-bottom: 1.65%;
   // transform: translateX(-4.75%);
    display: flex;
    //padding-left: .125em;
   // align-self: flex-start;
    justify-content:flex-start;
    
    flex-direction: column;
   // background-color: rgba(255, 0, 0, 0.467);
}
.confirmation-page-item-details {
    display: flex;
    flex-direction: column;
 //   max-height: 97.75%;
 //   background-color: rgba(46, 139, 86, 0.095);
    width: 35%;
    padding-top: 1.6%;
    padding-bottom: 1%;
    //padding-right: 2%;

    justify-content: flex-start;
    gap:.4em;
}
.confirmation-page-item-price {
    width: 15%;
    display: flex;
    justify-content: center;
   // background-color: rgba(0, 119, 255, 0.339);
    align-items: center;
  //  flex-direction: column;
    border-left: 1px dashed #353535;
    height: 100%;
}