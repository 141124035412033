@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
p {
    margin: 0;
    z-index: 999;
  }
  
  html {
    width: 100vw;
    height: 100vh;
    padding: 0;  
  }
.landing-container {
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
}
.main-content {
    min-height: 100vh;
    min-width: 100vw;
    background-image: url('./assets/background5.svg');
    background-position: center;
    background-repeat: no-repeat;
    transition: all .25s linear;
    background-size: cover;
    margin:0;
    //background-color: #151515;
    flex: 1;
   // overflow: hidden;
}
.dim-landing-container {
  transition: all .15s linear;
  filter: brightness(.6) blur(4px);
  pointer-events: none;
}

.inactive-landing-container {
  animation-name:animate-dimming;
  animation-timing-function:linear;
  animation-duration: .25s;
  pointer-events: none;
  animation-fill-mode: forwards;
}
@keyframes animate-dimming {
  from {
      filter: brightness(1) blur(0px);
  }
  to {   
      filter: brightness(.6) blur(4px);
  }
}

.main-content-lower {
  min-height: 67.5vh;
  min-width: 100vw;
  background-color: #151515;
  padding-top:32vh;
  margin:0;
  //background-color: #151515;
  flex: 1;
  overflow: visible;
}
.main-content-bottom {
  min-height: 70.5vh;
  align-items: center;
  min-width: 100vw;
  background-color: #151515;

  //background-color: #151515;
  flex: 1;
  overflow: visible;
}
.main-content-end {
  min-height: 38vh;
  min-width: 100%;

}
.content-container {
  //flex:1;
  display:flex;
  flex-direction: row;

}
.hero-text-container {
  max-width: 36vw;
 // min-height:58.3vh;
  max-height:fit-content;
  text-align: left;
  display: block;
  margin-top: 13vh;
  margin-left:8vw;
}
.hero-header-container {
 // max-width: 0.328vw;
  width: 100%;

  height: 50%;
  overflow: visible;
}
.hero-header {
  font-size:clamp(30px,8vw,66px);
  color:#efefef;
  font-weight: 800;
  //margin-top: .2em;
  //margin-bottom:.25em;
  color:#efefef;
  padding-bottom: 8px;
  z-index: 997;
  line-height: 66px;
  
  overflow: visible;
 // white-space: nowrap;

  font-family: 'Inter';
  letter-spacing: -.25px;
  text-shadow: 2px 4px 2px 4px rgba(0,0,0,0.25);
 // transition: all 0.2s linear;
  background: -webkit-linear-gradient(135deg,rgba(240, 240, 240,1), rgb(238, 238, 238,.7));
  -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.hero-subtext-container {
  width: 90%;
  padding-top: 1.2vh;
}
.hero-subtext {
  font-size:clamp(12px, 8vw, 17.5px);
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -.25px;
  font-family: 'Rubik';
  color:#a0a0a0;
}
.hero-button-container {
  max-width: 32.175vw;
  height: 5.75vh;
  margin-top: 8.5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.register-button {
  width: 48%;
  height:100%;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  transition: all .15s linear;
  display:flex;
  text-align: center;
  background-color:#a27caf;
  background-image: linear-gradient(45deg,#945aa6, #a27caf);
}
.register-button:hover {
  transform: scale(1.05);
}
.login-button {
  width: 48%;
  align-items: center;
  border-radius: 8px;
  transition: all .15s linear;
  justify-content: center;
  text-align: center;
  height:100%;
  transition: all .15s linear;
  display:flex;
  background-image: linear-gradient(75deg,#d0a434, #cdad5c);
 // background-color:#c1a04b;
}
.login-button:hover {
  transform: scale(1.05);
}
.hero-button-text {
  font-family: 'Inter';
  font-weight: 500;
  color:#151515;
  z-index: 1;
  font-size:clamp(12px, 3vw, 18px);
  align-self: center;
}
.grid-content {
  width:36.25vw;
  min-height:fit-content;
  right: 15vw;
  position: absolute;
  margin-top:6.125vh;
  margin-bottom:10vh;
}
.grid-content-main {
  width:100%;
  height:44vh;
  //align-items: flex-end;
 //justify-content: flex-start;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all .15s linear;
  border-radius: 28px;
  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.15);
  background-color: #99ACCF;
  background-image: linear-gradient(-115deg,#9bafd4, #5962a6);
}
.grid-main-text-container {
  padding-left:2.65vw;
  padding-bottom:2.65vh;
  justify-self: flex-end;
  max-height: fit-content;
  //background-color: #246264;
  margin-top: auto;
}
.grid-main-text {
  font-size: clamp(30px, 5vw, 60px);
  color:#eee;
  letter-spacing: .5px;
  z-index: 1;
  text-shadow: 3px 3px 2px rgba(81, 81, 81, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
       background-clip: text;

  font-family: 'Inter';
  font-weight: 800;
}
.grid-content-main:hover {
  transform: scale(1.025);
}
.grid-pair {
  max-width: 100%;
  min-height: 25vh;
  justify-content: space-between;
  display: flex;
  
  border-radius: 28px;
  margin-top: 2.5vh;
  flex-direction: row;
}
.grid-content-item {
  min-width: 48%;
  transition: all .15s linear;
  min-height:100%;
  text-decoration: none;
  align-items: flex-end;
  display: flex;
  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.15);
  border-radius: 28px;
  &:nth-of-type(1) {
    background-image: linear-gradient(-125deg,#e6b85d, #d67a3c);
  }
  &:nth-of-type(2) {
    background-image: linear-gradient(175deg,#318c77, #246264);
  }
}
.test-content-item {
  min-width: 48%;
  transition: all .15s linear;
  min-height:100%;
  align-items: flex-end;
  display: flex;
  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.15);
  border-radius: 28px;
  background-image: linear-gradient(175deg,#318c77, #246264);
}

.grid-item-text {
  font-size: clamp(20px, 3vw, 37px);
  color:#8ad3cd;
  //color:#92d8d2;
  //letter-spacing: .5px;
  letter-spacing:-.5px;
  z-index: 1;
  //text-shadow: 3px 3px 2px rgba(28, 28, 28, 0.25);

  line-height: 44px;
  font-family: 'Inter';
  font-weight: 600;


}
.grid-item-text-container {
  padding-left:1.5vw;
  padding-bottom:2.0vh;
}
.grid-item-text-container-alt {
  padding-left:1.5vw;
  padding-bottom:2vh;
}
.grid-item-text-container-bottom {
  padding-left:1.5vw;
  padding-bottom:2vh;
}
.grid-item-text-bottom {
  font-size: clamp(20px, 3vw, 37px);
  color:#eee;
  letter-spacing: .5px;
  z-index: 1;
  text-shadow: 3px 3px 2px rgba(28, 28, 28, 0.2);
  -webkit-background-clip: text;
  -moz-background-clip: text;
       background-clip: text;
  line-height: 38px;
  font-family: 'Inter';
  font-weight: 600;
}
.dash-symbol {
  font-weight: 400;
  font-size:clamp(20px, 3vw, 28px);
  display: inline;

  text-shadow: 3px 2px 1px rgba(28, 28, 28, 0.15);
}
.grid-item-text-left {
  font-size: clamp(22px, 3vw, 38px);
  //color:#fcee99ee;
  color:#0a0a0a;
  letter-spacing:-.5px;
  z-index: 1;
  text-shadow: 3px 2px 1px rgba(28, 28, 28, 0.15);
  -webkit-background-clip: text;
  -moz-background-clip: text;
       background-clip: text;
  line-height: 44px;
  
  font-family: 'Inter';
  font-weight: 600;
}
.grid-item-text-alt {
  font-size: clamp(22px, 3vw, 37px);
  color:#151515;
  letter-spacing: .5px;

  z-index: 1;
  text-shadow: 3px 2px 1px rgba(28, 28, 28, 0.15);
  -webkit-background-clip: text;
  -moz-background-clip: text;
       background-clip: text;
  line-height: 42px;
  
  font-family: 'Inter';
  font-weight: 600;
}
.grid-content-item:hover {
  transform: scale(1.05);
}
.grid-content-item-low {
  min-width: 48%;
  transition: all .15s linear;
  display: flex;
  align-items: flex-end;
  min-height:100%;
  border-radius: 28px;
  &:nth-of-type(1) {
    background-image: linear-gradient(125deg,#a65b75, #883b77);
  }
  &:nth-of-type(2) {
    background-image: linear-gradient(35deg,#a19292, #7f7069);
  }
}
.grid-content-item-low:hover {
  transform: scale(1.05);
}
.subcontent-wrapper {
  width: 100%;
  height: 25vh;

  display: flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
}

.subcontent-container {
  width: 100%;
  min-height: 28vh;
  margin-left:auto;
  margin-right:auto;
  margin-top: 3.25vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:1.675em;
  align-items: center;
}
.subcontent-item-container {
  width: 20%;
  background-color: #2a2a2a;
  border-radius: 16px;
  min-height: 100%;
  border:1px solid #3A3A3A;
  transition: all .15s linear;
  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.2);
}
.subcontent-item-container:hover {
  transform: scale(1.05);
}
.hr-item:hover {
  border: 1px solid rgb(83, 126, 206);
}
.education-item:hover {
  border: 1px solid goldenrod;
}
.health-item:hover {
  border: 1px solid rgb(212, 107, 107);
}
.environment-item:hover {
  border: 1px solid #2f8f78;
}
.content-lower-header {
  padding-left: 7.15vw;
  padding-bottom: 2vh;
}
.content-lower-text {
  font-size: clamp(16px, 3vw, 36px);
  color:#eee;
  font-family: 'Inter';
  letter-spacing: -.25px;
  text-shadow: 2px 4px 2px 4px rgba(0,0,0,0.25);
  font-weight: 600;
  background: -webkit-linear-gradient(-220deg,rgba(255, 255, 255,1),rgba(215, 215, 215,1));
  -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.content-lower-subtext {
  padding-top: 2.5vh;
  color: #eee;
  font-size: clamp(12px, 4vw, 18px);
  line-height: 28px;
  font-family: 'Rubik';
  font-weight: 300;
}


.star-icon {
  transform: rotate(90deg) scaleX(1.05) translateX(2.4em);
  position: relative;
  left: 75%;

  color:#151515;
  align-self: flex-end;
  min-width: 2em;
  min-height: 2em;
}
.subcontent-item-category {
  width: fit-content;

  height: fit-content;
  display: flex;
  align-items: flex-end;
  padding-left: 1.5vw;
  padding-top:2vh;
}
.category-text {
  font-size:clamp(12px, 4vw, 14px);
  font-family: 'Rubik';
  font-weight:400;
  letter-spacing: -.25px;
  z-index: 1;
  transform: scaleY(1.035);
}
.subcontent-item-figure {
  width: 100%;
  min-height: 50%;

  padding-left:1.5vw;
  padding-top:3.5vh;
  padding-bottom: .5vh;
  
}
.subcontent-figure-text {
  font-size:clamp(34px, 4vw, 44px);
  color:#efefef;
  font-family: 'Inter';



  font-weight: 800;
//  letter-spacing: -0.5px;
}
.subcontent-item-name {
  max-width: 88%;
  min-height: fit-content;
  display: flex;

  margin-left: auto;
  margin-right: auto;
  padding-top:1rem;
  align-self: center;
  border-top: 1px dashed #3e3e3e;
  padding-bottom:.5em;


}
.charity-name {
  font-style: italic;
  display: inline;
  font-weight: 400;
  color:#fff;
  letter-spacing: -.25px;
  padding-right:1.5px;
  z-index: 1;
}
.subcontent-name-text {
  font-size:clamp(10px, 3vw, 15px);
  font-family: 'Rubik';
  line-height: 22px;
  font-weight:300;
  vertical-align: bottom;
  align-self: flex-end;
  padding-right: 5px;
  z-index: 1;
  align-self: center;
  color:#efefef;
}
.category-hr {
  color:rgb(105, 154, 247);
}
.category-ed{
  color:rgb(227, 172, 34);
}
.category-h{
  color:rgb(243, 130, 130);
}
.category-ev {
  color:#40b499;

}

.subcontent-wrapper-bottom {
  width: 100%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
}.subcontent-container-bottom {
  width: 100%;
  min-height: 37vh;
  margin-left:auto;
  margin-right:auto;
  margin-top: 3.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:1.675em;
  align-items: center;
}
.subcontent-item-container-bottom {
  max-width: 27.25%;
  background: linear-gradient(45deg, #252525, #2a2a2a);
  border-radius: 16px;
  z-index: 1;
  min-height: 100%;
  border:1px solid #3A3A3A;
  transition: all .2s linear;
  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.2);
}
.subcontent-item-container-bottom:hover {
  //transform: scale(1.025);
  filter: brightness(1.175);

  &:nth-of-type(1) {
   // border: 1px solid rgba(83, 126, 206, 0.6);
    box-shadow: 0 0 20px rgba(47, 193, 233, 0.6); 
  }
  &:nth-of-type(2) {
   // border: 1px solid #2f8f79cc;
    box-shadow: 0 0 20px  rgba(36, 252, 137, 0.6); 
  }
  &:nth-of-type(3) {
    // border: 1px solid rgba(221, 180, 15, 0.6);
     box-shadow: 0 0 20px rgba(221, 180, 15, 0.6); 
   }
}

.subcontent-blur-container {
  min-width: 100%;
  min-height: 36%;
  flex-direction: row;
  display: flex;
  gap:1.675em;
  z-index: 0;
 // margin-left:auto;
 // margin-right:auto;
  

  
  justify-content: center;
  align-items: center;
}
.bottom-blur {
  min-width:27.25%;
 // background-color: #246264;
  min-height:36vh;
  border-radius: 16px;
  position: absolute;
  transition: all .15s linear;
}
.hr-blur {
  margin-left: 7.2639vw;
  left:0;
  z-index: -1;
  background-image: radial-gradient(steelblue, rgba(51, 78, 154,0.5));
}
.ed-blur {
  background-image: radial-gradient(goldenrod,rgba(182, 111, 30, 0.5));
}
.ev-blur {
  margin-right: 7.2639vw;
  right:0;
  background-image: radial-gradient(seagreen, rgba(37, 108, 94, 0.5));
}
.bottom-blur:hover {
  transform: scale(1.05);
}

.cause-icon-container {
  min-height: 40%;
  min-width: 100%;
  display:flex;
  align-items: center;
  padding-top:8%;
  //background-color: #444;
  justify-content: center;
}
.cause-icon-wrapper {
  width: 6.275vw;
  height:6.275vw;
  border-radius: 6.275vw;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  border:1px solid #eeeeee;
}
.hand-wrapper {
  background-color: rgb(69, 123, 167);
  box-shadow: 0 0 20px rgba(39, 200, 245, 0.6); 
}
.hand-icon {
  background-image: url('./assets/handshake.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 5.5vw;
  align-self: center;
  margin-left:auto;
  margin-right:auto;
  border-radius: 5.5vw;
  height:5.5vw;
}
.pencil-wrapper {
  background-color: rgb(200, 156, 44);
  box-shadow: 0 0 20px rgba(241, 178, 32, 0.6);
}
.pencil-icon {
  background-image: url('./assets/pencil3.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size:contain;
  width: 4.75vw;
  align-self: center;
  margin-left:auto;
  margin-right:auto;
  border-radius: 4.75vw;
  height:4.75vw;
}
.sprout-wrapper {
  background-color: rgb(108, 144, 108);
  box-shadow: 0 0 20px  rgba(36, 252, 137, 0.6); 
}
.sprout-icon {
  background-image: url('./assets/leaves2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 4.925vw;
  align-self: center;
  margin-left:auto;
  margin-right:auto;
  border-radius: 4.925vw;
  height:4.925vw;
}
.cause-title-container {
  max-width: 92.5%;
  //padding-left: 7%;
  //padding-right: 7%;
  padding-top: 7%;
  display: flex;
  overflow: hidden;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}
.cause-title {
  font-size: clamp(14px, 3vw, 20px);
  font-weight:500;
  font-family: 'Inter';
  color:#fff;

  letter-spacing: -.15px;

}


.cause-title:before,
.cause-title:after {
  background-color:#444444;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;

  vertical-align: middle;
  width: 50%;
}

.cause-title:before {
  right: .8em;
 // height:0px;
  margin-left: -50%;
  
}

.cause-title:after {
  left: .8em;
  margin-right: -50%;
}
.cause-description {
  color:#ababab; 
}

.cause-description-container {
  max-width: 87.5%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom:1.5vh;
  padding-top:2.125vh;
  text-align: center;
}
.cause-description {
  font-family: 'Rubik';
  font-size: clamp(10px, 4vw, 15px);
  font-weight: 300;
  color:#bababa;
  line-height: 24px;
}
.content-end-container {
  min-width: 55%;
  display: block;
  padding-left: 7.15vw;

}
.main-content-end-wrapper {
  //display: flex;
}
.content-end-header-wrapper {
  width: 25%;
}
.content-end-header {
  color:#fff;
  font-family: 'Inter';
  font-weight: 600;
  font-size:clamp(12px, 3vw, 16px)
}
.content-end-text-wrapper {
  min-width: 100%;
}
.content-end-text {
  font-size: clamp(20px, 5vw, 40px);
  color:#efefef;
  font-family: 'Inter';
  font-weight: 700;
  padding-top:2vh;
  letter-spacing: -.25px;
}
.content-end-subtext-wrapper {
  min-width: 100%;
  display: block;
  padding-top:2.5vh;
}
.content-end-subtext {
  color:#eee;
  font-size:clamp(12px, 3.5vw, 18px);
  font-family: 'Rubik';
  font-weight: 300;
}
.end-button-container {
  width: 30%;
  padding-top:4vh;
  height: 6vh;
  display: flex;
  justify-content: space-evenly;
  padding-left:7.5vw;
  gap:1.25vw;

}
.getting-started-button {
  border-radius: 12px;
  //border:2px solid rgb(221, 175, 60);
  background:linear-gradient(-135deg, rgb(214, 146, 50),rgb(232, 177, 95)) ;
  display: flex;
  transition: all .15s linear;
  height: 100%;
  align-items: center;
  min-width: 48%;
  justify-content: center;
}
.getting-started-button:hover {
  filter: brightness(1.15);
}
.getting-started-text {
  color:#101010;
  font-size:clamp(12px, 3vw, 18.5px);
  font-family: 'Inter';
  z-index: 1;
  letter-spacing: -.25px;
  font-weight: 500;
}
.explore-else-button {
  border-radius: 12px;
 // border:1.5px solid rgb(220, 173, 104);
  border:1.5px solid rgb(206, 169, 129);
  min-width: 48%;
  display: flex;
  height: 94.5%;
  transition: all .15s linear;
  margin-left: .375em;
  align-items: center;
  justify-content: center;
}
.explore-else-button:hover {
  filter:brightness(1.25)
}
.explore-else-text {
  color:#efefef;
  font-size:clamp(12px, 3vw, 18px);
  font-family: 'Inter';
  letter-spacing: -.5px;
  font-weight: 500;
}
.icon-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 4vw;
  height: 3vw;
  width: 92.5%;
  position: relative;
  padding-bottom: 3vh;
  gap:2.65vw;

}
.git-logo-button {
  outline: none;
  border:none;
  max-width: fit-content;
  max-height: fit-content;
  border-radius: 2.2vw;
  background-color: transparent;
}
.mail-button {
  outline: none;
  border:none;
  max-width: fit-content;
  max-height: fit-content;
  border-radius: 2.2vw;
  background-color: transparent;
}
.linkedin-button {
  outline: none;
  border:none;
  max-width: fit-content;
  max-height: fit-content;
  border-radius: 2.2vw;
  background-color: transparent;
}
.git-logo {
  background-image: url('./assets/github-white.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.1vw;
  height:2.1vw;
}
.linkedin-logo {
  width: 2.1vw;
  height:2.1vw;
  background-image: url('./assets/linkedin.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mail-icon{
  width: 2.25vw;
  height:2.25vw;
  color:#fff;
  background-image: url('./assets/gmail.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.discover-link {
  text-decoration: none;
}
.discover-scene-container {
  min-width: 71.5%;
  min-height: 77.8%;

  position: absolute;
  background-color: transparent;
}
.discover-scene {
  background-image: url('./assets/planetscene2.svg');
  background-position: center;
  background-size: cover;
  filter: brightness(.95) contrast(.95) ;
  background-repeat: no-repeat;
  width: 71.5%;
  margin-top: 1.75vh;
  margin-right: .125vw;
  height: 77.8%;
  align-self: flex-end;
  z-index: 1;
  //background-color: red;

 // aspect-ratio: 16/9;
}