.subcontent-profile-container {
    width: 100%;
    height: 100%;
   // background-color: #2f8f78;
    //margin-top: 3.25vh;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    //transform: translateX(-.1em);
    gap:1.3em;
    align-items: center;
  }
  .profile-favorite-item-container {
    min-width: 23.54%;
    background-color: #202020;
    overflow: hidden;
    border-radius: 16px;
    height: 100%;
    border:1px solid #303030;
    transition: all .15s linear;
    box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.2);
  }
  .profile-favorite-item-container:hover {
 //   transform: scale(1.025);
  }
  .hr-item:hover {
    border: 1px solid rgba(93, 136, 216, 0.9);
  }
  .education-item:hover {
    border: 1px solid rgba(218, 165, 32, 0.9);
  }
  .health-item:hover {
    border: 1px solid rgba(212, 107, 107, 0.9);
  }
  .environment-item:hover {
    border: 1px solid #36a188ee;
  }
  .research-item:hover {
    border: 1px solid rgba(80, 187, 191, 0.9);
  }
  .animals-item:hover {
    border: 1px solid rgba(122, 188, 69, .9);
  }
  .community-item:hover {
    border: 1px solid rgba(154, 129, 106, 0.9);
  }

  .profile-favorite-icon {
    color:#555555;
    width:1.475em;
    height: 1.475em;
    transition: all .2s linear;
    transform: scaleX(1.05) translateX(-1em) translateY(.3em); 
  }
  .profile-favorite-icon:hover {
    filter: brightness(1.5);
  }

  .profile-favorite-type-container {
    height: fit-content;
    width: 100%;
   // background-color: red;
    display: flex;
  }
  .profile-favorite-item-category {
    max-width: 100%;
  
    min-height: 4vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
 //   background-color: red;
    padding-left: 1.5vw;
  //  padding-top:2vh;
  }
  .category-text {
    font-size:clamp(12px, 4vw, 14px);
    font-family: 'Rubik';
    font-weight:500;
    letter-spacing: -.25px;
    z-index: 1;
    transform: scaleY(1.035);
  }
  .profile-favorite-item-figure {
    width: 100%;
    height: 28%;
    padding-left:1.5vw;
    padding-top:2.125vh;
    padding-bottom: .85vh;
    
  }

/**/
/**/
.category-favorite-text {
    font-size:clamp(9px, 4vw, 14px);
    font-family: 'Rubik';
    font-weight:400;
    letter-spacing: .25px;
    z-index: 1;
    transform: scaleY(1.035) translateX(0em);
  }
.small-favorite-wrapper {
    //background-color: #c875fb6c;
    background-color: #505050;
}
.mid-favorite-wrapper {
   // background-color: #f967c178;
      background-color: #505050;
}
.large-favorite-wrapper {
    background-color: #b575269a;
    background-color: #505050;
}
  .profile-favorite-figure-text {
    font-size:clamp(34px, 4vw, 40px);
    color:#eee;
    font-family: 'Inter';
    font-weight: 800;
    letter-spacing: -0.5px;
  }
  .profile-favorite-item-name {
    max-width: 100%;
 
    //background-color: red;
   // min-height: max-content;
    display: flex;
    align-items: flex-start;
   // text-align: center;
    padding-left: 1.35em;
    padding-right: 1.5em;
    justify-content: flex-start;
    padding-top: .675em;



    border-top: 1px dashed #3e3e3e;
    padding-bottom:.5em;
  }
  .profile-favorite-name {
    font-style: italic;
    display: inline;
    font-weight: 400;
    color:#fff;
    letter-spacing: -.25px;
   // padding-right:1.5px;

  }
  .profile-favorite-name-text {
    font-size:clamp(10px, 3vw, 16.5px);
    font-family: 'Inter';
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight:600;
   // margin-left: auto;
    //margin-right: auto;
    align-self: flex-start;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  //  padding-right: 5px;
    color:#efefef;
  }
  .category-hr {
    color:rgb(105, 154, 247);
  }
  .category-ed{
    color:rgb(227, 172, 34);
  }
  .category-h{
    color:rgb(223, 120, 120);
  }
  .category-ev {
    color:#40b499;

  }

  .profile-favorite-item-type-wrapper {
    width: fit-content;
    min-height: fit-content;
    padding-left: .75em;
    padding-right: .75em;
    padding-top: .07em;
    padding-bottom: .07em;
    margin-left: 1.5em;
    margin-right: auto;
    display: flex;
    transform:  translateX(-.25em) ;
    align-self: flex-start;
    justify-content: flex-start;
   // margin-bottom: .15em;
   // padding-bottom:.3%;
    border-radius:5px;
    
}
.profile-favorite-item-type-text {
    font-size: clamp(10px, 3vw, 12.25px);
    color:#eee;
    font-family: 'Rubik';
   // letter-spacing: .1px;
    line-height: 24px;
    font-weight: 600;
}
