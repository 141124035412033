.settings-container {
    height: 100vh;
    max-width: 100vw;
    overflow-y: scroll;
   // background-color: rgba(70, 180, 132, 0.274);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

}
.settings-content {
    min-height: 100%;
    width: 90%;
    padding-left: 10%;
    padding-top: 3.75em;
    padding-bottom: 4em;
    margin:0;
    align-self: flex-start;
    transition: all .25s linear;
}


.settings-header-container {
    display: flex;
    flex-direction: row;
    width: 92.25%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
 // background-color: rgba(70, 131, 180, 0.274);
}
.settings-image-wrapper {
    min-width: 4.5em;
    min-height: 4.5em;
 
  //  outline:3px solid #eee;
    display: flex;
 // padding-bottom: .125em;
    align-items: center;
   // filter: blur(1px);
  //  background:linear-gradient(45deg, rgba(30, 160, 125, 0.65),#67de7999,#6777de99);
    background-color: rgba(89, 201, 151, 0.591);
    border-radius: 15px;
}
.settings-image-text {
    font-size: clamp(18px, 6vw, 40px);
    color:#eee;
    font-family: 'Rubik';
    font-weight: 400;   
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    margin-left: auto;
    text-shadow: 4px 3px 2px rgba(28, 28, 28, 0.25);
    margin-right: auto;
    transform: translateY(-.0125em);
}

.settings-header-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 100%;
    //padding-top: 0.25em;
 // align-self: center;
 // justify-content: center;
 // background-color: rgba(255, 0, 0, 0.223);
}
.settings-header-text {
    font-size: clamp(18px, 3vw, 28px);
    font-family: "Inter";
    letter-spacing: -.5px;
    font-weight: 800;
    color:#eee;
    line-height:40px;
}
.settings-link-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:.25em;
    transform: translateX(-0.15em);
}
.settings-header-subtext {
    font-size: clamp(10px, 3vw, 14.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.settings-link-icon {
    color:#ababab;
    transform: translateY(.0125em);
    width: 1.0em;
    height: 1.0em;
}


.manage-settings-container {
    max-width: 92.25%;
    height: fit-content;
    display: flex;
    padding-top: 3em;
   // background-color: rgba(46, 139, 86, 0.422);
}
.manage-settings-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  // padding-right: 2.25em;
  // align-items: center;
  // background-color: rgba(117, 46, 139, 0.422);
    padding-bottom: 1em;
    border-bottom: 1px dashed #5a5a5a;
}
.manage-header-settings-text {
    font-size: clamp(14px, 3vw, 22px);
    font-family: "Inter";
    letter-spacing: -.25px;
    font-weight: 600;
    color:#eee;
    line-height:36px;
}
.manage-header-subtext {
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.personal-container {
    display: flex;
    position: relative;
    max-width: 92.25%;
    padding-top: 1.25em;
    padding-bottom: 2.75em;
    border-bottom: 1px dashed #5a5a5a;
    min-height: 35vh;
 // border:1px solid #454545;
 // align-items: center;
 // background-color: rgba(218, 165, 32, 0.244);
}
.nickname-details-container {
    width: 22.5%;
    height: 100%;
    padding-top: .05em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
 // @function background-color: rgba(255, 192, 203, 0.266);
}
.nickname-details-text {
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color:#fff;
    line-height:36px;
}
.nickname-details-subtext {
    padding-top: .125em;
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 22px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}
.settings-fullname-container {
    display: flex;
    padding-top: .25em;
    width: 77.5%;

    align-self: flex-end;
    height: fit-content;
    gap:1em;
    justify-content: space-between;
}
.settings-input-details-subtext {

    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 16px;
    letter-spacing: 0px;
    font-weight: 400;
    color: #a0a0a0;
}
.settings-input-wrapper {
    width: 77.5%;
    height: fit-content;
    display: flex;
    padding-top: .5em;
    align-self: flex-end;
    flex-direction: column;

}
.settings-input-wrapper-small {
    width: 50%;
    height: fit-content;
    display: flex;
    padding-top: .5em;
    align-self: flex-end;
    flex-direction: column;

}


.personal-input-container {
    min-width: 70%;
    padding-right: .1em;
    min-height: 100%;
    display: flex;
 // padding-top: 1.25em;
    margin-left: auto;
    border-radius: 16px;
    flex-direction: column;
    gap:.75em;
 // background-color: rgba(255, 236, 192, 0.266);
}
.personal-display-wrapper {
    width: 77.5%;
    height:34.5%;
    margin-top: .825em;
    background-color: #151515;
    outline:1px solid #454545;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius:16px;
    margin-left: auto;
 // margin-right: auto;
}
.personal-display-input-wrapper {
    width: 100%;
    height:3.125em;
    margin-top: .65em;
    background-color: #151515;
    outline:1px solid #454545;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all .15s linear;
    border-radius:16px;
    margin-left: auto;
 // margin-right: auto;
}
.personal-display-input-wrapper:hover {
    filter: brightness(1.25);
}
.personal-name-input-wrapper {
    width: 100%;
    height:3.125em;
    margin-top: .65em;
    background-color: #151515;
    outline:1px solid #454545;
    display: flex;
    flex-direction: row;

    transition: all .15s linear;
    border-radius:16px;
    margin-left: auto;
 // margin-right: auto;
}
.personal-name-input-wrapper:hover {
    filter: brightness(1.25);
}
.personal-display-url {
    min-width: 27%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 3.25em;
    background-color: #252525;
    display: flex;
    padding-left: .5%;
    align-items: center;
    justify-content: center;
}
.personal-edit-wrapper {
    min-width:92.5%;
    height: 100%;
 // background-color: #353535;
    display: flex;
 // border-radius: 5px;
    border-top-right-radius: 16px;
    align-items: center;
    justify-content: flex-start;
    //padding-left: 3%;
    border-bottom-right-radius: 16px;
}
.personal-icon-wrapper {
    min-width: 7.5%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 100%;
  // background-color: #252525;
    display: flex;
  // padding-left: .125%;
    align-items: center;
    justify-content: center;
}
.personal-wrapper-alt {
    min-width: 16%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 100%;
  // background-color: #252525;
    display: flex;
  // padding-left: .125%;
    align-items: center;
    justify-content: center;
}
.personal-url-text {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #a0a0a0
}
.personal-display-container {
    min-width: 67%;
    height: 100%;
 // background-color: #353535;
    display: flex;
 // border-radius: 5px;  
    align-items: center;
    justify-content: flex-start;
    padding-left: 2.5%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.personal-display-text {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 22px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #eee;
}
.personal-input-text {
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 20px;
    letter-spacing: -.25px;
    font-weight: 400;
    background-color: #151515;
    border-top-right-radius: 16px;
    width:100%;
    //padding-left: 3%;
    height: 100%;
    //background-color: red;
    border:none;
    outline:none;
    border-bottom-right-radius: 16px;
    color: #eee;
}
.personal-input-text::placeholder {
    color:#6a6a6a;
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 20px;
    letter-spacing: -.25px;
    font-weight: 400;
}
.personal-name-input-text {
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
   // line-height: 20px;
    letter-spacing: -.25px;
    font-weight: 400;
    background-color: #151515;
    border-top-right-radius: 16px;
    width:100%;
    //padding-left: 3%;
    height: 100%;
    //background-color: red;
   // padding-left: 1.5%;
    border:none;
    outline:none;
    border-bottom-right-radius: 16px;
    color: #eee;
}
.personal-name-input-text::placeholder {
    color:#6a6a6a;
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 20px;
    letter-spacing: -.25px;
    font-weight: 400;
}
.personal-edit-icon {
    width: 1.05em;
    height: 1.05em;
    color:#6a6a6a;
    transform: translateX(.075em);
}

.privacy-container {
    display: flex;
    position: relative;
    max-width: 92.25%;
    padding-top: 1.25em;
    padding-bottom: 2.25em;
    border-bottom: 1px dashed #5a5a5a;
    min-height: 10vh;
 // border:1px solid #454545;
 // align-items: center;
 // background-color: rgba(218, 165, 32, 0.244);
}
.settings-public-wrapper {
    width:100%;
   // background-color: #4a4a4a;
    min-height: 22px;

    text-align: left;
//    padding-top:1.85vh;
    display: flex;
    justify-content: space-between;
    gap:4em;
    flex-direction: row;   
    
    align-items: center;
}
.settings-switch-wrapper {
  //  background-color: rgba(70, 131, 180, 0.17);
    max-width: fit-content;
    gap:1em;
    display: flex;
    flex-direction: column;
    height: fit-content;
}
.personal-details-change {
    display: flex;
    flex-direction: column;
    width: 54%;
    padding-top: 0.5em;
    margin-left: auto;
    align-self: center;
   //align-self: flex-end;
  // align-self: flex-end;
    //gap:1em;
    justify-content: flex-start;
    align-items: flex-start;

   // align-items: dl;
   // background-color: rgba(255, 0, 0, 0.141);
}

.settings-switch-container {
    text-align: center;
    align-self: center;
    justify-self: center;
    transform: rotate(180deg);
   // padding-top:1px;
  }
  .settings-toggle-switch {
    position: relative;
   // box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.15);
    width: 30px;
  
    text-align: left;
  }
  .checkbox {
    display: none;
  }
  .settings-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: none;
    border-radius: 20px;
  }
  .settings-inner {
    display: block;
    width: 200%;
    margin-left: 0;
    transition: all 0.125s ease-in;
  }
  .settings-inner:before,
  .settings-inner:after {
    float: right;
    width: 50%;
    min-height: 14.5px;
    padding: 0;
    line-height: 14.5px;
    color: #fff;
    box-sizing: border-box;
  }
  .settings-inner:before {
    content: "";
    //background: linear-gradient(-315deg, rgba(237, 203, 118, 1),rgba(224, 181, 111, 0.8));
  //  background-color: rgba(237, 203, 118, 0.875);
    background-color: rgba(50, 187, 157, 0.875);
    color: #fff;
  }
  .settings-inner:after {
    content: "";
    background-color: #bbb;
 //   background:linear-gradient(315deg, #bbb, #abababab);
    color: #fff;
    text-align: right;
  }
  .settings-switch {
    width: 9.25px;
    height: 9.25px;
    margin: 2.5px;
    background: #1a1a1a;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 13.5px;
    border-radius: 20px;
    transition: all 0.125s ease-in;
  }
  .checkbox:checked + .settings-label .settings-inner {
    margin-left: -100%;
  }
  .checkbox:checked + .settings-label .settings-switch {
    left: 1px;
  }
  .settings-footer-container {
    padding-top: 2.5em;
    padding-right: .25em;
  }

  .logout-container {
    min-width: 9.5%;
    height: fit-content;
    padding-top: .275em;
    padding-bottom: .25em;
    border-radius: 8px;
    border: 1px solid #3a3a3a;
    align-self: flex-start;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-.35em) translateY(.75em);
    transition: all .15s linear;
    background-color: #2a2a2a;
    z-index: 999;
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight: 400;
    transition: all .15s linear;
    color: #a0a0a0;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  .logout-container:hover {
    background-color: #1a1a1a;
    border: 1px solid rgba(220, 117, 117,.8);
    color:rgb(220, 117, 117);
  }
  .settings-save-changes-container {
    width: 12%;
    height: fit-content;
    padding-left: .65em;
    padding-right: .65em;
    padding-top: .275em;
    padding-bottom: .25em;
    border-radius: 8px;
    border: 1px solid rgb(35, 113, 186);
    align-self: flex-start;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(.25em) translateY(.75em);
    transition: all .15s linear;
    background-color: #2a2a2a;
    z-index: 999;
    font-size: clamp(7px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight: 400;
    transition: all .15s linear;
    color: #eee;
    background-color:rgb(35, 113, 186);
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  .settings-save-changes-container:hover {
    background-color: #1a1a1a;
    border: 1px solid rgb(64, 145, 221);
    color:rgb(64, 145, 221);
  }

  .settings-loading-text-container {
    z-index: 1;
    width: 92.5%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1.25rem;
  }
  .settings-loading-text {
    font-family: 'Rubik';
    color:#a0a0a0;
    font-size: clamp(12px, 2vw, 14px);
    font-weight: 400;
    align-self: center;
  }