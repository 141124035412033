.campaign-content {
  width: 100%;
  height: 100vh;
  display: flex;

  flex-direction: column;
}

.campaign-page-container {
  height: 100%;
  width: 90%;
  padding-left: 10%;
  display: flex;
  z-index: 2;
  flex-direction: column;
  //flex:1;
  //padding-top: 3.925rem;
 // padding-top: 3.925em;
  //overflow-y: scroll;

  overflow-x: hidden;
  //margin:0;
  //overflow-y: auto;
  //overscroll-behavior-y:contain;
 // background-color: rgba(255, 0, 0, 0.213);
}
.campaign-blur {
  width:  100vw;
  //left:.5%;

  left:-10%;
 // background-color: red;
  //background: linear-gradient(180deg,rgba(247, 77, 165, 0.6),rgba(238, 77, 93, 0));
//  height:13vh;
  //background-color: rgba(200, 79, 91, 0.8);
  transform: translateY(-1vh);
 // box-shadow: 0 0 20vh rgba(214, 72, 86, 0.85); 
  filter: blur(6px);
  //top:0;
  position: absolute;
 // overflow-y: hidden;
  //left:0;
//  border-bottom-right-radius: 25%;
//  border-bottom-left-radius: 25%;
  z-index: -1;
  animation-name: animate-blur;
  animation-duration: .4s;
  animation-delay: .1s;
  animation-fill-mode: forwards;
  animation-timing-function:ease-out;
}
.campaign-page {
  width: 100vw;
  display: flex;
  flex-direction: column;

 // overscroll-behavior-y:contain;
//box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  background-color: hsla(0,0%,8%,.753);
 // overflow-x: hidden;

}
.campaign-stats-container {
    width: 41.5%;
    height: 100%;
   // transform: translateY(-.025em);
    //padding-left: 1em;
   //background-color: rgba(46, 139, 86, 0.207);
    display: flex;
  //  transform: translateX(-.5em);
    flex-direction: column;
    margin-left: auto;
  //  overflow-y: hidden;
}

.campaign-details-container {
  // overflow-y: hidden;
    padding-top: .5em;
    width: 92.25%;
    min-height: 36vh;
   // overflow: hidden;
   // padding-left: .125em;
   // background-color: rgba(0, 139, 139, 0.228);
    display: flex;
  
}
.campaign-donations-wrapper {
//  overflow-y: hidden;
    height: 90%;
  //  background-color: rgba(255, 0, 0, 0.211);
    width: 40%;
   // margin-top: .25em;
    display: flex;
    padding-top: 1.25em;

}
.manage-campaign-container {
  max-width: 92.25%;
  min-height: 10vh;
  display: flex;
  padding-top: 2.75em;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
  border-bottom: 1px dashed #5a5a5a;
 // justify-content: center;
 // background-color: rgba(46, 139, 86, 0.422);
}
.manage-campaign-header-container {
  width: 100%;
  height: 100%;
  display: flex;
  //flex-direction: column;
  justify-content: flex-start;
// padding-right: 2.25em;
// align-items: center;
 //background-color: rgba(117, 46, 139, 0.422);
  //padding-bottom: 1em;

}
.campaign-figure-wrapper {
 // overflow-y: hidden;
  //  height: ;
  //  background-color: rgba(255, 0, 0, 0.211);
    width: 60%;
    height: 100%;
   // margin-top: .25em;
 //  margin-left: auto;
    display: flex;
}
.campaign-insights-wrapper {
  overflow: hidden;
    //margin-top: 1.25em;
    width: 100%;
    border-radius: 20px;
   // background-color: rgba(70, 131, 180, 0.27);
    height:100%;
    //height:57.25%
}
.campaign-doughnut-container {
//  overflow-y: hidden;
  width: 94.5%;
  background-color: #252525bd;
  overflow-x: hidden;
  border-radius: 16px;
  padding-left:1.5em;
  display: flex;
 // box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.125);
  align-items: center;
//  border:1px solid #303030;
  height: 100%;
 // outline:1px solid #303030;
  transition: all .15s linear;
}
.campaign-insights-wrapper-alt {
  //overflow: hidden;
    margin-top: 1.25em;
    width: 96%;
    margin-left: auto;
    border-radius: 20px;
   // background-color: rgba(70, 131, 180, 0.27);
   // min-height:96.25%;
    display: flex;
    height: 90%;
    justify-content:space-evenly;
  
    gap:.875em;
    //padding-bottom: .125em;
    flex-direction: column;
    //align-items: center;
    //height:57.25%
}
.campaign-insights-wrapper-row {
  //  overflow-y: hidden;
    
    width: 100%;
    height: 46.5%;
    justify-content: space-between;
    border-radius: 20px;
    display: flex;
}
.campaign-insights-wrapper-item {
//  overflow-y: hidden;
    width: 48.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.125);
    border-radius: 20px;
    background-color: #252525bd;
}
.campaign-page-donations-container {
//  overflow-y: hidden;
    height: 100%;
  //  background-color: rgba(0, 255, 217, 0.211);
    width: 100%;  
    display: flex;
   // padding-left: .125em;
}
.campaign-insight-container {
//  overflow-y: hidden;
    width: 94.5%;
    background-color: #252525bd;
    overflow-x: hidden;
    border-radius: 16px;
    //padding-left:1.5em;
    display: flex;
    margin-left: auto;
    align-items: center;
  //  border:1px solid #303030;
    height: 100%;
   // outline:1px solid #303030;
    transition: all .15s linear;
  //  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.2);
  }
  .campaign-insights-subcontent-header {
  //  overflow-y: hidden;
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color:#eee;
    line-height:24px;
   // width: 70%;
}
.campaign-insights-subcontent-subtext {
 // overflow-y: hidden;
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 600;
    color:#eee;
    //color:#eeeeeebd;
    //line-height:22px;
}
  .campaign-insights-subcontent-text {
   // overflow-y: hidden;
    font-size:clamp(24px, 3vw, 34px);
    color:#eee;
    font-family: 'Inter';
    font-weight: 800;
    text-align: center;
    letter-spacing: -.5px;
    line-height: 40px;
}
.campaign-page-like-icon-wrapper {
//  overflow-y: hidden;
  min-width:2.75em;
  min-height: 2.75em;
  background-color: #5a5a5a;
  display: flex;
  transition: all .2s linear;

  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 2.75em;
}
.campaign-page-like-icon-wrapper:hover {
  filter: brightness(1.2);
}
.campaign-page-like-icon-wrapper-alt {
 // overflow-y: hidden;
  transition: all .2s linear;
  min-width:2.75em;
  min-height: 2.75em;
  //transform: translateX(-.125em);
  background:linear-gradient(45deg, rgba(66, 96, 245, 0.7),#f1579faa,#fbe432cc);
  //background-color: #eee;
 // background-color: rgb(190, 64, 106);
  display: flex;

  justify-content: center;
  align-items: center;
  border-radius: 2em;
}

.campaign-donation-item {
 // overflow-y: hidden;
  display: flex;
 // padding-bottom: .1em;
  border-bottom: 1px dashed #2e2e2e;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 25%;
}
.campaign-item-last {
  border-bottom: 1px solid transparent;
}


.campaign-details-container-low {
 // overflow-x: hidden;
 //overflow-y: hidden;
// overflow-y: hidden;
 // overflow-y: visible;

  width: 92.25%;
  display: flex;
  //height: 55vh;
  min-height:fit-content;
  margin-top: .125em;
}
.campaign-donations-container {
//  overflow-y: visible;
  min-height: 100%;
 // max-height: 80vh;
  width: 57%;  
  padding-left: .25em;
 // overflow-x: hidden;
}
.campaign-donations-list {
  //overflow-y: hidden;
  width: 100%;
  height: 57vh;
  //height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  padding-top: 1em;
  justify-content:flex-end;

//  background-color: rgba(46, 139, 86, 0.198);
}
.campaign-donations-list-content {
  //overflow-y: hidden;
  width: 100%;
  height: 100%;
 // background-color: #252525bd;
  border-radius: 20px;
 // padding-left: 1.5em;
  display: flex;
  flex-direction: column;
//  background-color: #252525bd;
 // padding-left: .025em;
  //margin-top: .5em;
//   background-color: rgba(46, 103, 139, 0.198);
}

// campaign post
.campaign-post-container-alt {
  // overflow-y: visible;
  width: 100%;
  height:34vh;
  //max-height: 30vh;
  display: flex;

 // background-color: #515151bd;
  justify-content: flex-start;
  border-radius: 20px;
}
.campaign-post-container {
 // overflow-y: visible;
  width: 100%;
  height:80vh;
  max-height: 80vh;
  display: flex;

  justify-content: flex-start;
  border-radius: 20px;
 // background-color: #515151bd;
}
.campaign-post-list {
 // overflow-y: visible;
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  gap:.5em;
}
.campaign-post-content-container {
  width: 95%;
  height: 95%;
  align-self: center;
  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
}
.campaign-post-content-alt {
  width: 89%;
  //min-height: 36%;
  height: 74%;
  //height: 92%;
  display: flex;
 // margin-top: 3%;
 // padding: 2.5%;
  padding-left: 2.75%;
  padding-right: 2.75%;
  //padding-bottom: 1.25%;
 // padding-top: 1.75%;
  margin-top: 4.35%;
  padding-bottom: 2.5%;
  padding-top: 3.25%;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  background-color: rgb(34,34,34);
 // background-color: #51cd9d;

  border-radius: 16px;
 // background-color: #252525bd;
  transform: translateX(-.25em);
}
.campaign-post-content {
  width: 89%;
  height: 92%;
  display: flex;
 // padding: 2.5%;
  padding-left: 2.75%;
  padding-right: 2.75%;
  padding-bottom: 1.25%;
  padding-top: 1.75%;
  flex-direction: column;
  align-self: flex-end;
  justify-content: center;
  background-color: rgb(34,34,34);
 // background-color: #51cd9d;
  border-radius: 16px;
 // background-color: #252525bd;
  transform: translateX(-.25em);

}
.campaign-post-header {
  display: flex;
  width: 100%;
  height: fit-content;
  //justify-content: center;
  gap:1em;
 // padding-top: .25%;
  align-items: center;
}
.campaign-post-profile-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.campaign-post-profile-image-wrapper {
  width: 2.5em;
  height: 2.5em;
  background-color: #5a5a5abd;
  border-radius: 2.5em;
}
.campaign-post-top-content {
  display:"flex";
  flex-direction: "column";
  width: 100%;
 // padding-bottom: 1%;
  height: 25%;

}
.campaign-post-caption-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap:.5em;
  padding-top: 1.35em;

  padding-left: .35em;

}
.campaign-post-image-wrapper {
  border-radius: 20px;
 // background-color: #1a1a1a;

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  border-radius: 16px;
  height:64%;
  display: flex;
}
.campaign-post-image {
 // background-image: url('./assets/v9.png');
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1920/1080;
  background-size: cover;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  max-height: 100%;
  border-radius: 16px;
  //box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.15);
//  background:linear-gradient(35deg, steelblue, seagreen);

}
.campaign-post-footer {
  display: flex;
  width: 98%;
  height: 10%;
  padding-top: 1%;

  //background-color: #202020;
  margin-top: auto;
  align-items: flex-end;
  justify-content: flex-end;
  gap:1em;
}
.campaign-post-caption-title {   // may be unused
  font-size: clamp(14px, 3vw, 18px);
  font-family: "Rubik";
  letter-spacing: -.25px;
  font-weight: 600;
  color:#fff;
 // line-height:38px;
}
.campaign-post-caption-text {   // may be unused
  font-size: clamp(14px, 3vw, 16px);
  font-family: "Rubik";
  letter-spacing: -.25px;
  font-weight: 400;
  color:#eee;
  //line-height:38px;
}
.campaign-post-caption-subtext {
  font-size: clamp(10px, 3vw, 15px);
  font-family: "Rubik";// padding-left: .025em;
  line-height: 20px;
  letter-spacing: -.25px;
  font-weight: 400;
  color: #aaa;
  user-select: none;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
}
.campaign-post-header-text {
  font-size: clamp(10px, 3vw, 16px);
  font-family: "Rubik";
  letter-spacing: -.25px;
  font-weight: 600;
  color:#ccc;
  //color:#eeeeeebd;
  //line-height:22px;
}
.campaign-post-header-subtext {
  font-size: clamp(10px, 3vw, 16px);
  font-family: "Rubik";
  letter-spacing: -.25px;
  font-weight: 400;
  color:#a0a0a0;
  //color:#eeeeeebd;
  //line-height:22px;
}
.campaign-post-profile-text {
  user-select: none;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  text-shadow: 2px 2px 1px rgba(18, 18, 18, 0.2);
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  color:#eee;
  font-family: "Rubik";
 // transform: translateY(-.01em);
  font-size: clamp(10px, 3vw, 20px);
}

.campaign-post-footer-item-wrapper {
  border-radius: 10px;
  padding-left: .7em;
  padding-right:.85em;
  padding-top:.5em;
  padding-bottom:.5em;
  display: flex;
  transition: all .15s linear;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  gap:.5em;
  background-color: #353535;
}
.campaign-post-footer-item-wrapper:hover {
  filter: brightness(1.2);
}
.campaign-post-icon {
  width: 1rem;
  height: 1rem;
  color:#ccc;
}
.campaign-post-image-icon {
  width: .875rem;
  height: .875rem;
  color:#ccc;
}
.campaign-post-icon-repost {
  width: 1rem;
  height: 1rem;
  color:#bbb;
  transform: scaleX(1.1) scaleY(1.2);
}
.campaign-post-icon-views {
  width: 1rem;
  height: 1rem;
  color:#ccc;
  transform: scaleX(1.1) scaleY(1.15);
}
.campaign-post-icon-views-allow {
  width: 1rem;
  height: 1rem;
  color:#ddd;
  transform: scaleX(1.125) scaleY(1.2) translateY(0.02em);

}
.campaign-post-icon-views-alt {
  min-width: 1em;
  min-height: 1em;
  color:#ccc;
  transform: scaleX(1.1) scaleY(1.15);
}
// end of campaign post


.campaign-post-edit-icon-wrapper {
  width: 1.35em;
  height: 1.35em;
  align-self: center;
 // margin-left: .125em;
  margin-left: auto;
  margin-right: .5em;

  //margin-top: .125em;


}
.campaign-post-edit-icon-alt {
  width: 1.6em;
  height: 1.6em;
  border-radius: 1.6em;
  position: absolute;
  transition: all .15s linear;
  align-items: center;
  right: .8em;
  display: flex;
  background-color: #ddd;
  box-shadow: 2px 2px 1px 2px rgb(10,10,10, 0.2);
  justify-content: center;
  transform: translateX(-50%) translateY(-50%);
}
.campaign-post-edit-icon-alt:hover {
  transform: translateX(-50%) translateY(-50%) scale(1.125);
}
.campaign-post-edit-icon {
  width: 1.6em;
  height: 1.6em;
  border-radius: 1.6em;
  position: absolute;
  transition: all .15s linear;
  align-items: center;
  right: .8em;
  display: flex;
  background-color: #ddd;
  box-shadow: 2px 2px 1px 2px rgb(10,10,10, 0.2);
  justify-content: center;
  transform: translateX(-155%) translateY(65%);
}
.campaign-post-edit-icon:hover {
  transform: translateX(-155%) translateY(65%) scale(1.15);
}
.edit-image-icon {
  width: 65%;
  height: 65%;
  color:rgb(34, 34, 34);
}
.campaign-page-donate-button {
  width: 64%;
  //width: 54%;
  min-height: 100%;
 // margin-top: .25em;
  border-radius: 20px;
  background-color: #eee;
  margin-left: auto;

  display: flex;
  justify-content: center;
  margin-right:.75em;
//  transform: translateY(-.5em);
  align-self: flex-end;
 // margin-bottom: .25em;
}
.campaign-page-donate-subcontainer {
  //width:24.25%;
  width: 19.25%;
  align-self: flex-start;
  padding-top:.75em;
  
  //background-color: rgba(255, 0, 0, 0.096);
  display: flex;
  margin-left: auto;
  justify-content: space-evenly;
  align-items: center;
  height: 2.75em;
  gap:1em;
}
.campaign-page-pen-icon-wrapper {
  min-width:2.75em;
  min-height: 2.75em;
  background-color: #5a5a5a;
  display: flex;
  transition: all .2s linear;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 2.75em;
}
.campaign-page-pen-icon-wrapper:hover {
  filter: brightness(1.2);
}
.campaign-post-link-wrapper {
  animation: show-input .5s ease-in-out;
  animation-delay: .5s;
  animation-fill-mode: forwards;
}

.campaign-post-link-text {
  animation: typing .5s ease-in-out;
  animation-fill-mode: forwards;
}
.campaign-post-link-input {
  background-color: transparent;
 // height: fit-content;  
  border:none;
  outline:none;
  padding-top:0;
  padding-bottom:0;
  padding-right:0;
  margin-right: auto;
//  padding-top:0;

  //background-color: rgba(255, 0, 0, 0.25);
 //background-color: red;
 //background-color: transparent;
  margin:0;
  overflow: hidden;
  font-size: clamp(10px, 3vw, 15px);
  font-family: "Rubik";
  letter-spacing: -.25px;
  transform: translateY(-0.025em);
  font-weight: 600;

  color:#959595;
  height: 100%;
}
.post-footer-text {
  user-select: none;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
}
.campaign-post-link-input-wrapper {
  background-color: #353535;
  //background-color: rgba(234, 0, 255, 0.238);
}

@keyframes show-input {
  from {
    width: fit-content;
  }
  to {
    width: 100%;
  }
}