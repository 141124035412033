@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600&display=swap');


.catalog-main-container {
    min-width: 100vw;
    min-height: 100vh;

    overflow: scroll;
}
.catalog-main-content {
  //  min-height: 47vh;
    min-width: 100vw;
   // background-image: url('./assets/background8.svg');
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
    margin:0;
    //background-color: #151515;
    flex: 1;
    overflow: hidden;
    transition: all .25s linear;
}
.active-container {
  animation-name:animate-lighten;
  animation-timing-function:linear;
  animation-duration: .25s;
  animation-fill-mode: forwards;
  transition: all .25s linear;
}
@keyframes animate-lighten {
  from {
      filter: brightness(.6) blur(4px);
  }
  to {   
      filter: brightness(1) blur(0px);
  }
}

.dim-container {
  transition: all .15s linear;
  filter: brightness(.6) blur(4px);
  overflow: hidden;
}

.catalog-header-container {
    width: 86.125%;
    height:40%;
    //padding-left: 6.25em;
   // display: flex;
  overflow: hidden;

    align-self: center;
    margin-left: auto;
    margin-right:auto;
    padding-top:5vh;
  }
  .catalog-end-container {
    margin-top: 7.5vh;
    padding-right: 1.45vw;
  }
  .catalog-header-text {
    color:#efefef;

    font-family: 'Inter';
    font-weight: 600;
    font-size:clamp(12px, 3vw, 16.5px);
   

    letter-spacing: -.125px;
  }
  .catalog-main-text-container {
    min-width: 100%;
  }
  .catalog-main-text {
    font-size: clamp(24px, 6vw, 45px);
     color:#efefef;
    font-family: 'Inter';
    font-weight: 700;
    padding-top:3vh;

    background: -webkit-linear-gradient(220deg,rgba(255, 255, 255,1),rgba(210, 210, 210,.95));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -.5px;
  }
  .catalog-header-subtext-wrapper {
    width: fit-content;
    display: block;
    padding-top:4.5vh;

  }
  .catalog-header-subtext {
    color:#bbbbbb;
    font-size:clamp(12px, 3.5vw, 16px);
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 32px;
  }
  .catalog-feedback-link {
    color:rgb(238, 195, 88);
    text-decoration: none;

  }
  .header-space {
    display: block;
    margin: 22px 0;
    content:"";

  }

.charity-catalog-container {
    min-width: 100vw;
    min-height:100vh;

    padding-top:7.25vh;
    display: flex;

}
.charity-tag-container {
    width: 47.5vw;
    max-width: 47.5vw;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-right:8.5vw;
    margin-left:auto;
    min-height: fit-content;
}
.panel-container {
   
    width: 24.5vw;
 

    padding-left: 7vw;
    align-items: center;
    min-height: 100vh;
    transition: all ease-in-out .5s;
    max-height: fit-content;
   // background-color: red;
 
}
.panel-wrapper {
  min-width: 100%;

  transition: all ease-out .4s;

  //background-color: aqua;

}
.search-catalog-container {
  display: flex;
  margin-bottom: .5vh;
  padding-right: 1.75vw;
  min-height: 2vh;
  background-color: #252525;
  border-radius: 20px;
  border: rgba(0, 0, 0, 0.15) solid 2px;
  max-width: 100%;
}
.search-catalog {
  display: block; 
  height:5.5vh;
  width: 100%; 
  border-radius: 20px; 
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1); 
  border: rgba(0, 0, 0, 0) solid 2px;
  background-color: #252525;
  padding-left:1.5vw;
  outline: none;

  font-size: clamp(12px, 3vw, 14.5px);
  color:#efefef;
  font-weight: lighter;
  padding-top:.25vh;
  font-family: 'Inter';

 // color:#2a2a2a;
}
.search-catalog::placeholder {
  align-self: center;


  font-size: clamp(12px, 3vw, 14.5px);
}
.search-icon {
  color:#8a8a8a;
  width: 20px;
  height: 20px;
  align-self: center;

 // background-color: ;
}
.charity-list {
  list-style: none;
  margin: 0;
  padding:0;
}
.charity-list-item {
  margin-top: 1rem;
}
.catalog-items {
  padding:0;
  max-height: fit-content;
}
.showing-text-container {
  width: fit-content;
  display: flex;
  margin-right: auto;
  //text-align: right;
  z-index: 1;
  padding-bottom: .5rem;
  padding-left:.5rem;
 
}
.loading-text-container {
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
}
.loading-text {
  font-family: 'Rubik';
  color:#a0a0a0;
  font-size: clamp(12px, 2vw, 14px);
  font-weight: 400;
  align-self: center;
}
.payment-info-container {
  position: absolute;
  background-color: #252525;
  max-height: 100vh;
  max-width: 40vw;
  //min-width: 55vw;
  right: auto;
  align-self: center;
  left: auto;

 // background-color: red;  // min-width: 1;
}
.loading-text-alt {
  user-select: none;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  overflow: hidden;
  font-size: clamp(12px, 2vw, 14px);
  font-weight: 400;
  align-self: center;
  content:""
}