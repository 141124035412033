html {
    overflow: scroll;
}
.create-campaign-container {
        width: 100%;
       // background-color: rgba(70, 180, 132, 0.274);
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        flex:1;
        height: fit-content;
        transition: all .2s linear;
     //   padding-bottom: 15em;
}
.create-campaign-content {

    width: 95vw;
    margin-left: auto;
    transition: all .2s linear;
  //  padding-top: 3.5em;
  //  padding-bottom: 4em;
    //margin:0;
   // background-color: red;
    align-self: flex-start;

    transition: all .25s linear;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.create-campaign-header-container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    justify-content: center;
    padding-top: 3.75em;
}

.create-campaign-inner-text {
    font-size: clamp(18px, 3vw, 40px);
    font-family: "Inter";
    margin-left: auto;
    margin-right: auto;
    letter-spacing: -.25px;
    font-weight: 800;
    color:#eee;
    line-height:50px;
}
.create-campaign-inner-subtext {
    font-size: clamp(10px, 3vw, 16.5px);
    font-family: "Rubik";
    margin-left: auto;
    margin-right: auto;
    line-height: 36px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #959595;
}
.create-campaign-inner-wrapper {
    width: 44%;
    min-height: 100vh;
   // height: fit-content;
    overflow-y: visible;
   // padding:2em;


    margin-left: auto;
    margin-right: auto;
    display: flex;
   // background-color: rgba(26, 255, 0, 0.285);
    flex-direction: column;
    align-self: center;
    //position: relative;
}
.create-campaign-inner-content {
    display: flex;
    padding-top: 3.75%;
    width: 100%;


    //padding-top:2em;
    flex-direction: column;
   // background-color: rgba(128, 0, 128, 0.316);
}
.create-campaign-confirmation-wrapper {
    width: 40%;
    //height: 76.5%;
    height: 72.5%;
    padding:2em;
    left:0;
    right: 0;
    margin-left: auto;
    padding-top: 13%;
    margin-right: auto;
    display: flex;
 //   background-color: rgba(26, 255, 0, 0.285);
    flex-direction: column;
    align-self: flex-start;
  //  justify-content: center;
    //position: relative;
  //  align-items: center;
    //position: absolute;
}
.create-campaign-confirm-button {
    width: 100%;
    border-radius: 12px;
    margin-top:3.5%;
    padding-bottom: .875em;
    padding-top: .875em;
    margin-left: auto;
    margin-right: auto;
   // background: linear-gradient(-135deg, rgba(211, 185, 119, 0.875), rgba(212, 153, 64, 0.875));
     background-color:rgba(108, 202, 177, 0.8);
   //   background-color: rgba(79, 198, 180, 0.875);
    display: flex;
    text-decoration: none;
    filter: contrast(1.05) brightness(1.05);
    transition: all .15s ease-in-out;
    justify-content: center;
   // background: linear-gradient(90deg, rgba(28, 190, 166, 0.875), rgba(211, 184, 116, 0.875));
    align-items: center;
   // height: 9.25%;
}
.create-campaign-confirm-button:hover {
    filter: contrast(1.05) brightness(1.2);
}
.create-campaign-field-wrapper-alt {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    
}
.create-campaign-field-alt {
    width: 100%;

}
.create-campaign-fields-container {
    min-width: 100%;
    min-height:100%;
    gap:1.75em;
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    // /background-color: rgba(255, 0, 0, 0.19);
}
.create-campaign-field-wrapper {
    min-width: 100%;
    gap:.25em;
    //height:15%;
    display:flex;

    flex-direction: column;
   // background-color: rgba(46, 139, 86, 0.313);
}
.create-campaign-inner-input {
    width:88.5% ;
    border-radius: 12px;
   // -left: 1em;

    color:#eee;
    outline:none;
    padding-top: .125em;
    font-size: clamp(8px, 3vw, 14.5px);
    border:none;
    //transform: translateX(.125em);
    background-color: transparent;
    height:100%;
}
.create-campaign-inner-input::placeholder {
    font-size: clamp(10px, 3vw, 14px);
    font-family: "Rubik";
 //   margin-right: auto;
 //   line-height: 34px;


    font-weight: 400;
    color: #656565;
}
.create-campaign-share-button {
    width:95%; 
    height:5vh;
  //  margin-left: auto;
    margin-right: auto;
    background:#252525;
    justify-content:flex-start;
    display: flex;
    //background-color: transparent;
    flex-direction: column;
    border-radius: 10px;
    //align-items: center;
    transition: all .15s linear;
    padding:1px;
    user-select: none;
    filter:brightness(1.15)
   // justify-content: center;
}
.create-campaign-share-button :hover {
  //  filter: brightness(1.25);
}
.option-hovered {
    transition: all .15s linear;
  //  filter: brightness(1.25);
}
.option-hovered:hover {
    filter: brightness(1.25);
}
.create-inner-option-content-alt {
    background-color: #252525;
    width: 100%;
    min-height: 5vh;
    gap:.6em;
    display: flex;
 
    align-items: center;
    justify-content: center;
    transition: all .15s linear;
   // border-radius: 10px;
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";
   //@at-root 

   // line-height: 34px;
    letter-spacing: -.25px;

    font-weight: 400;
   // padding-right: .5em;
    color: #eee;
}
.create-inner-option-content-item {
    background-color: #1e1e1e;
    width: 100%;
    min-height: 5vh;
    gap:.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .15s linear;
   // border-radius: 10px;
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color: #eee;
    &:nth-of-type(even) {
        background-color: #252525;
    }
}
.create-inner-option-content-main {
   // background-color: #252525;
    width: 100%;
    min-height: 5vh;
    gap:.6em;
    display: flex;
   
    align-items: center;
    justify-content: center;
    transition: all .15s linear;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";
   //@at-root 

   // line-height: 34px;
    letter-spacing: -.25px;

    font-weight: 400;
   // padding-right: .5em;
    color: #eee;
}
.top-option {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.bottom-option {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.create-inner-option-content-main:hover {
    filter: brightness(1.25);
}
.create-inner-option-content-on {
    //filter: brightness(1.075);
    transition: all .15s linear;
   // padding-left: 1.5em;
  //  border-radius: 10px;
    filter: brightness(1.15);
    //gap:.7em;
}
.create-campaign-color-button {
    width:95%; 
    min-height:5vh;
    margin-left: auto;
  //  margin-right: auto;
    background:#252525;
    display: flex;
    border-radius: 10px;
    align-items: center;
    transition: all .15s linear;
    padding:1px;
    justify-content: flex-start;
  //  gap:1em;
  filter:brightness(1.15)
}


.create-campaign-color-option {
    width: 1em;
    height: 1em;
    border-radius:3px;
    //outline:1px solid #555;
    border:none;
    outline:none;
    color:transparent;
    margin-left:.5em;
  //  background-color: #656565;
    align-self: center;
}
::-webkit-color-swatch {
    border-color: rgba(255, 0, 0, 0);
}
::-moz-color-swatch {
    border-color: rgba(255, 0, 0, 0);
}
.create-campaign-color-option:focus {
    outline-width: 0;
}
.create-campaign-color-text {
    font-size: clamp(10px, 3vw, 16px);
    font-family: "Rubik";
   // letter-spacing: -.25px;
    font-weight: 400;
    padding-right: .125em;
   // padding-right: .5em;
    color: #656565;
   // padding-left: 1em;
  // display: inline;
   padding-top: .0875em;
}
.create-campaign-color-text-input {
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    outline: none;
    border: none;
    font-weight: 400;
    padding-top: .125em;

    background-color: transparent;
   // padding-right: .5em;
    color: #eee;
    
}
.create-campaign-color-text-input::placeholder {
    color:#656565;
} 
.create-campaign-color {
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
   // padding-right: .5em;
    color: #eee;
}
.create-campaign-loading-container {
    width: fit-content;
    display: flex;
   // background-color: red;
    margin-bottom: 1.5em;
    justify-content: center;
    margin-left: auto;
    height: 75%;
    flex-direction: column;
    margin-right: auto;
    align-self: center;
    height: 100%;

    align-items: center;
}
.category-dropdown-list {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    list-style: none;
    padding:0;
    margin:0;
    margin-top: .35em;
   // max-height: 35vh;
    //min-height: fit-content;
  }


  .create-campaign-inner-input-wrapper {
    width: 96.5%;

    display: flex;
    align-items: center;
   // font-size: clamp(8px, 3vw, 14.5px);
    justify-content: flex-start;
    border-radius: 12px;
    height: 2.5em;
    overflow: visible;
   padding-top: .175em;
   padding-left: 3.5%;
   padding-bottom: .175em;
   outline:none;
   border:none;
   //width: 100%;
   color:#efefef;
   //align-items: center;
   background-color: #252525;

}
.footer-space {
   // min0-: 20vh;
   // color:"white";
   // padding-top: 1em;
}