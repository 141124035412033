.donate-page {
    width: 100vw;
    height: 100vh;
   // background-image: url('./assets/background5.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin:0;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
}
.test-text {
    color:#eee;
    font-size: clamp(16px, 5vw, 32px);
    font-family: 'Inter';
    font-weight: 800;
    align-self: center;
}
.loading-container {
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: fit-content;
    height: fit-content;
}
.donate-page-container {
    display: flex;
    width: 82.5%;
    margin-left: auto;
    justify-content: flex-start;
    height: 100%;
    padding-bottom: .25em;
    //align-items: center;
   padding-top: 4%;

  //  background-color: rgba(255, 0, 0, 0.203);
}
.checkout-container {
    display: flex;
    flex-direction: column;
    min-width: 52.5%;
    min-height: 100%;
   // padding-left:.5em;
  //  padding-right: 1.25em;
    justify-content: center;

    //padding-top:.5em;
    //background-color: rgba(218, 165, 32, 0.483);
}
.checkout-content {
    height: 100%;
    min-width: 100%;
    padding-left:3em;
    //background-color: rgba(46, 142, 81, 0.379);
}
.checkout-header-container {
    max-width: 87.5%;
    
    padding-bottom: .5em;
    max-height: fit-content;
    border-bottom: 1px solid #555555;
  //  background-color: rgba(110, 46, 142, 0.379);
}
.checkout-header-text {
    font-size: clamp(18px, 3vw, 32px);
    color:#eee;
    line-height: 42px;
    font-family: 'Inter';
    font-weight: 500;
 //  padding-top:3vh;
   // background: -webkit-linear-gradient(220deg,rgba(255, 255, 255,1),rgba(210, 210, 210,.95));
   // -webkit-background-clip: text;
   // -webkit-text-fill-color: transparent;
    letter-spacing: -.5px;
    }
.checkout-details-container {
    max-width: 87.5%;
    display: flex;
    padding-top: 1.35em;
    flex-direction: column;
    padding-bottom: 1.35em;
   // background-color: rgba(0, 255, 255, 0.494);
    max-height: 30%;
    border-bottom: 1px solid #555555;
}

.checkout-details-header {
    font-size: clamp(10px, 3vw, 18px);
    color:rgba(238, 238, 238, 0.875);
    font-family: 'Rubik';
    font-weight: 400;
    padding-left: .25em;
    letter-spacing: -.25px;
}

.dedication-container {
   // margin-top:.5em;
    display: flex;
    padding-left: .25em;
    flex-direction: column;
   // min-height: 15%;
    align-items: flex-start;
   // align-items: center;
    justify-content: center;
    gap:.5em;
    padding-top: 2.5%;
    max-width: 100%;
   // background-color: rgba(238, 0, 255, 0.294);
}
.dedication-text {
    font-size: clamp(8px, 3vw, 16px);
    color:#a0a0a0;
    font-family: 'Rubik';
    line-height: 20px;
    font-weight: 400;
}
.mentions-container {
    padding-top: 1.5em;
}
.checkout-option-container {
    max-width: 87.5%;
    display: flex;
    padding-top: 1.5em;
    flex-direction: column;
    //background-color: rgba(0, 255, 255, 0.494);
    min-height: 28%;
  //  padding-bottom: 3.75em;
  //  border-bottom: 1px solid #555555;
}
.checkout-option-header {
    font-size: clamp(10px, 3vw, 18px);
    color:rgba(238, 238, 238, 0.875);
    font-family: 'Rubik';
    font-weight: 400;
    padding-left: .25em;
    letter-spacing: -.25px;
}
.stripe-payment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 30vh;
}
.payment-method-container {
    width:100%;
    height: 100%;
    //transform: scaleY(.975);
    align-items: center;
    padding-top:1.325em;
    flex-shrink: 0; 
   // filter: grayscale(1) contrast(2) brightness(.85) invert(1);    
    padding-left:.2em ;
  //  background-color: rgba(0, 102, 255, 0.494);
    //transition: all .15s linear;
}
.checkout-footer-wrapper {
    width: 100%;
    margin-top: 5%;
    height: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
}
.card-method-container {
    max-width:100%;
    padding-left: .25em;
    z-index: 10;

    filter: grayscale(1) contrast(1.1) brightness(1.05) ;    
}
.checkout-option-footer {
    width: 100%;
    
    min-height: fit-content;
    text-align: center;
    overflow: hidden;
    
   // transform: translateY(.5em);
}
.terms-bold-switch-container {
    transition: all .15s linear;
}
.terms-bold-switch-container-alt {
    color:rgb(243, 197, 79);
    transition: all .15s linear;
}
.terms-bold-switch-container-alt:hover {
    color:"#aaa";
    filter: brightness(1.2);
}
.terms-bold-switch-container:hover {
    filter: brightness(1.2);
}
.checkout-terms-footer {
    width: 100%;
   
    min-height: fit-content;
    text-align: center;
    transition: all .25s linear;
   
}
.checkout-option-footer-text {
    font-size: clamp(9px, 2vw, 14px);
    color:#aaaaaa;
    font-family: 'Rubik';
    font-weight: 400;
    padding-left: .25em;
    letter-spacing: -.25px;
}
.terms-text {
    font-size: clamp(9px, 2vw, 14px);
    color:#aaaaaa;
    font-family: 'Rubik';
    display: inline;
    font-weight: 400;
    padding-left: .25em;
    letter-spacing: -.25px;
    font-size: clamp(8px, 2vw, 13px);
}
.terms-bold-text {
    font-size: clamp(9px, 2vw, 14px);
    color:#ababab;
    font-family: 'Rubik';
    font-weight: 600;
    padding-left: .25em;
    display: inline;
    letter-spacing: -.25px;
    font-size: clamp(8px, 2vw, 13px);
}
.terms-bold-text-alt {
    font-size: clamp(8px, 2vw, 14px);
    color:rgb(243, 197, 79);
    font-family: 'Rubik';
    font-weight: 600;
    padding-left: .25em;
    display: inline;
    letter-spacing: -.25px;
    font-size: clamp(8px, 2vw, 13px);
}

.checkout-option-footer-text:before {
    background: linear-gradient(90deg, #45454500 5%, #454545ff 95%);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
.checkout-option-footer-text:after {
  background: linear-gradient(90deg, #454545ff 5%, #45454500 95%);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.checkout-option-footer-text:before {
  right: 1em;
  margin-left: -50%;
}
.checkout-option-footer-text:after {
  left: 1em;
  margin-right: -50%;
}



.checkout-confirm-container {
    width: 100%;

    transition: all .2s linear;
    padding-top: 1em;
    padding-left: .25em;
}
.confirm-checkout-button:hover {
    transform: scale(1.025);
}
.confirm-checkout-button {
    border-radius: 32px;
    align-self: center;

   // background-color: rgb(214, 178, 87);
     background-color: rgba(236, 236, 236, 0.875);
    //background: linear-gradient(135deg, rgba(93, 118, 219, 0.9) 5%, rgba(112, 77, 207, 0.9) 95%);
    margin-left: auto;
    text-decoration: none;
    margin-right: auto;
    //outline: 1px solid rgba(208, 206, 202, 0.9);
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    min-height: 3em;
    //border:1px solid #eee;
    transition: all .15s linear;
}
.checkout-confirm-button:hover {
    transform: scale(1.05);
}
.confirm-checkout-text {
    font-size: clamp(12px, 3vw, 18px);
    font-family: "Inter";
    transform: translateY(.025em);
    font-weight: 500;
    margin-left: auto;
    padding-left: 2.25em;
    z-index: 1;
    margin-right: auto;
    background-color: transparent;
    //color:#eee;
    color:#0a0a0a;
}
.arrow-checkout-icon {
    padding-right: .75em;
    transform: translateX(-.75em);
 //   color: #e5e5e5;
    width: 1.6em;
    //padding-bottom: .025em;
    height: 1.6em;
    color:#0a0a0a;
}
.cart-container {
    display: flex;
    flex-direction: column;
    width: 45%;
  //  padding-bottom: 1.875em;

    min-height: 100%;
    //transform: translateX(-.25em);
    //justify-content: ;
//    background-color: blue;
}
.cart-content {
    min-height: 100%;
    width: 100%;
    padding-left:2.25em;
    display: flex;
    flex-direction: column;
    
   // padding-top:1.2em;
  //  padding-bottom: 1em;
   // background-color: rgba(30, 30, 30, 0.5);
   // border-radius:10px;
}
.cart-header-container {
    max-width: 88.5%;
    height:100%;
    padding-bottom: .5em;
   // max-height: fit-content;
    border-bottom: 1px solid #555555;
  //  background-color: rgba(110, 46, 142, 0.379);
}
.cart-header-text {
    font-size: clamp(12px, 3vw, 24px);
    color:#eee;
    line-height: 42.5px;
    font-family: 'Inter';
    font-weight: 500;
 //  padding-top:3vh;
   // background: -webkit-linear-gradient(220deg,rgba(255, 255, 255,1),rgba(210, 210, 210,.95));
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    letter-spacing: -.5px;
}
.cart-item-list {
    display: flex;
    max-width: 88.5%;
    flex-direction: column;
    min-height: 100%;
 //   background-color: #eee;
}
.first-item {
margin-top: 0;
}
.cart-item {
    display: flex;
   // margin-top: 1px;
   // border-top: 1px solid #151515;
    border-bottom: 1px dashed #3a3a3a;
    width: 100%;

    //height: 19.65%;
   // padding-top: .5em;
   // padding-bottom: .5em;
}
.cart-item-info {
    min-height: 100%;
    width: 80%;
    //padding-top: 2.75%;
  //  transform: translateX(1%);
    display: flex;
   // padding-left: 1%;
    justify-content:center;
    
    flex-direction: column;
   // background-color: rgba(255, 0, 0, 0.467);
}

.cart-item-title {
    font-size: clamp(10px, 3vw, 16px);
    color:#eee;
    font-family: 'Rubik';
    line-height: 24px;
    font-weight: 600;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.cart-item-text {
    font-size: clamp(10px, 3vw, 14.5px);
    color:#a0a0a0;
    font-family: 'Rubik';

    line-height: 20px;
    font-weight: 400;
}
.cart-item-type-wrapper {
    width: fit-content;
    height: fit-content;
    padding-left: 3.5%;
    padding-right: 3.5%;
    transform: scale(.9) translateX(-4.5%);
    margin-top: 3%;

    padding-bottom:.65%;
    border-radius:5px;
    background-color: rgba(248, 212, 121, 0.788);
}
.cart-item-type-text {
    font-size: clamp(10px, 3vw, 14.5px);
    color:#0a0a0a;
    font-family: 'Rubik';
    padding-top: 3.5%;
    line-height: 24px;

    font-weight: 400;
}
.cart-item-price {
    width: 22.5%;
    display: flex;
    justify-content: center;
   // background-color: rgba(0, 119, 255, 0.339);
    align-items: center;
    border-left: 1px dashed #2e2e2e;
    min-height: 100%;
}
.item-price-text {
    font-size: clamp(10px, 3vw, 17.25px);
    color:#eee;
    font-family: 'Rubik';
    padding-top: 3.5%;
    line-height: 24px;
    font-weight: 600;
}
.donate-page-item-info {
    max-height: 98.125%;
    width: 80%;
    padding-top: 2.5%;
    padding-bottom:3.25%;
    transform: translateX(0.25%);
    display: flex;
    //padding-left: .125em;
   // align-self: flex-start;
    justify-content:flex-start;
    
    flex-direction: column;
   // background-color: rgba(255, 0, 0, 0.467);
}
.donate-page-item-price {

        width: 20%;
        display: flex;
        justify-content: center;
       // background-color: rgba(0, 119, 255, 0.339);
        align-items: center;
      //  flex-direction: column;
        border-left: 1px dashed #353535;
        height: auto;
    
}