.feed-container {
    height: 100vh;
    max-width: 100vw;
    overflow-y: scroll;
   // background-color: rgba(70, 180, 132, 0.274);
    overflow-x: hidden;
    display: flex;


}
.feed-content {
    min-height: 100vh;
    width: 72.5%;
    margin-left: auto;
  //  margin-right: auto;
    padding-top:2.75em;
    padding-bottom: 1em;
  //  padding-left: 3em;
    //padding-left: 5%;
    padding-right: 5%;
  //  margin:0;
    align-self: flex-start;
 //   background-color: rgba(70, 131, 180, 0.208) ;
}

.feed-item-link {
    text-decoration: none;
    transition: all .2s linear;
}
.feed-image-link {
    text-decoration: none;
    border-radius: 3.25em;
    transition: all .2s linear;
}
.feed-image-link:hover {
   // transform: scale(1.05);
    box-shadow: 0px 0px 0px 3.5px rgba(80,80,80,0.3);
}
.feed-item-link:hover {
    filter: brightness(1.2);
}
.feed-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
 // background-color: rgba(70, 131, 180, 0.274);
}
.feed-list-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  // padding-right: 2.25em;
  // align-items: center;
  // background-color: rgba(117, 46, 139, 0.422);
    padding-bottom: 1em;
    border-bottom: 1px dashed #5a5a5a;
}
.feed-header-text {
    font-size: clamp(14px, 3vw, 22px);
    font-family: "Inter";
    letter-spacing: -.25px;
    font-weight: 600;
    color:#eee;
    line-height:36px;
}
.feed-header-subtext {
    font-size: clamp(10px, 3vw, 15.5px);
    font-family: "Rubik";// padding-left: .025em;
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight: 400;
    color: #aaa;
}

.feed-list-container {
    max-width: 100%;
    height: fit-content;
    flex-direction: column;
    //gap:1.5em;
    display: flex;
    padding-top: .5em;
//    background-color: rgba(46, 139, 86, 0.422);
}




.feed-friends-item {
    display: flex;
    border-bottom: 1px dashed #353535;
   // background-color: rgba(255, 0, 0, 0.142);
  // border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding-top: 1.125em;
    padding-bottom: 1.125em;
    width: 100%;
    height: 100%;
}
.feed-friends-item-first {
    display: flex;
    border-bottom: 1px dashed #353535;
   // background-color: rgba(255, 0, 0, 0.142);
  // border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding-top: .875em;
    padding-bottom: 1em;
    width: 100%;
    height: 100%;
}
.feed-friends-item-last {
    display: flex;
  //  border-bottom: 1px dashed #353535;
   // background-color: rgba(255, 0, 0, 0.142);
  // border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    height: 100%;
}
.feed-friends-image-container {
    min-width:7%;
    min-height: 100%;
   // border-radius: 2.5em;
   display: flex;
   justify-content: flex-start;
   align-self: flex-start;
    //background-color: #149da07a;
}
.feed-friends-image-wrapper {
    min-width: 3.25em;
    height: 3.25em;
    border-radius: 3.25em;
    align-self: center;
    background-color: #2e2e2e;
    display: flex;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    justify-content: center;

}
.feed-item-text-alt {
    font-size:clamp(10px,3.25em,26px);
    color:#eee;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    line-height: normal;
    align-self: center;
    font-family: 'Rubik';
  }
.feed-friends-item-info {
    height: 100%;
    width: 100%;
 //   padding-left: .25em;
 //   padding-top: .05em;
    display: flex;
   // padding-left: 1%;
    justify-content:center;
   // border-radius: 5px;
   // padding-left: 1em;
    flex-direction: column;
   // background-color: #1a1a1a;
}
.feed-item-header p {
    display:inline;
}
.feed-friends-item-title {
    font-size: clamp(10px, 3vw, 16px);
    color:#eee;
    font-family: 'Inter';
    line-height: 22px;
    font-weight: 600;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    transition: all .15s linear;
}
.feed-friends-item-title-alt {
    font-size: clamp(8px, 3vw, 16px);
    color:#a0a0a0;
    font-family: 'Inter';
    line-height: 22px;
    font-weight: 500;
    transition: all .15s linear;
    display: -webkit-box;
    padding-right: .125em;
    padding-left:.125em;
    overflow: hidden;

    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.feed-friends-text-wrapper {
    display: flex;
    align-items: center;
    gap:0.4em;
 //   transform: translateX(-.1em);
}
.feed-donation-item-text {
    font-size: clamp(8px, 3vw, 14px);
    color:#a0a0a0;
    font-family: 'Rubik';

    line-height: 22px;
    font-weight: 400;
}
.feed-donation-item-text-alt {
    font-size: clamp(8px, 3vw, 14px);
    color:#eee;
    font-family: 'Rubik';

    line-height: 22px;
    font-weight: 400;
}
.feed-item-date-container {
   // background-color: red;
    padding-right: 1em;
    display: flex;
    align-items: center;
    gap:.75em;
    justify-content: flex-end;
}
.feed-donation-caption {
    width: 80%;
    height:fit-content;
    padding-top: .125em;
    //background-color: red;
}
.feed-like-icon {
    color:#555555;
    width:1.35em;
    height: 1.35em;
    
  }