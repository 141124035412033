.side-console-container {
    min-height: 100vh;
    position: fixed;
    left:0;
    width: 17.5vw;
    overflow: hidden;
    background-color: #252525bb;
    z-index: 999;

}

.side-console-container-alt {
    min-height: 100vh;
    position: fixed;
    left:0;
    width: 17.5vw;
    overflow: hidden;
    background-color: #252525;
    z-index: 999;
}
.side-console-container-closed {
    min-height: 100vh;
    position: fixed;
    left:0;
    width: 4.5vw;
    //padding-right: .1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
 //   padding-top: .125em;
    background-color: #252525dd;
    z-index: 999;
}
.console-header-container {
    width: 100%;
    height: fit-content;
    display: flex;
    padding-left: 1.75em;
    padding-top:1em;
    justify-content: flex-start;
}
.console-header-container-alt {
    width: 100%;
    height: fit-content;
    display: flex;
    padding-left: .95em;
    padding-top:1em;
  //  background-color: red;
    justify-content: flex-start;
}
.console-header-container-closed {
    width: 100%;
    height: fit-content;
    display: flex;
  //  padding-left: 1.75em;
    padding-top:1em;
    justify-content: center;
    align-items: center;
}
.console-logo-text-container {
    display: flex;
    align-items: center;
    padding-top: .5em;
    text-decoration: none;
    gap: .7275em;

//   padding-left: .65rem;
}
.console-logo-text-container-closed {
    display: flex;
    align-items: center;
    margin-top: .75em;
    padding-bottom: .375em;
    padding-left: .375em;
    padding-right: .375em;
    padding-top: .375em;
    align-self: flex-end;
    transform: translateX(.035em);
    transition: all .15s linear;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    filter: hue-rotate(30deg);
    box-shadow: 0px 0px 0px 0px rgba(76,76,76,0.6);
    background:linear-gradient(45deg, rgba(55, 88, 252, 0.6),#39ead590,#c9ff3590);
  //  background-color: #404040;
}
.console-logo-text-container-closed:hover {
    box-shadow: 0px 0px 0px 5px rgba(76,76,76,0.4);
}
.console-logo-text {
    font-size:clamp(12px,4vw,22px);
    font-weight: 600;
    padding-top:.1875em;
    z-index: 999;
    letter-spacing: -.25px;
    //white-space: nowrap;
    font-family: 'Inter';
   // text-shadow: 2px 4px 2px 4px rgba(0,0,0,0.25);
    transition: all 0.2s linear;
    color:#eee;
   // background: -webkit-linear-gradient(right,rgb(238, 238, 238), rgb(210, 210, 210));
   // -webkit-background-clip: text;
   // -webkit-text-fill-color: transparent;
}
.console-app-logo {
    background-image: url('./assets/logo-alt.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: visible;
    width: 1.75em;
    height: 1.75em;
    filter: brightness(.95);
    transform: scaleX(1.05) translateY(.0725em);
    align-items: center;
}
.console-app-logo-closed {
    background-image: url('./assets/logo-alt.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: visible;
    width: 1.4em;
    height: 1.4em;
    filter: brightness(.9);
    transform: scaleX(1.075) translateY(-.0375em) translateX(.075em);
    align-items: center;
    align-self: center;
}
.console-content-container {
    display: flex;
    flex-direction: column;
 //   gap:1.5em;
    min-width: 100%;
 //   padding-top: 2.6em;
   padding-top: 1.6em;
    min-height:30%;
}
.first-content {
    padding-top: 1.5em;
    //padding-top: 2.5em;
}
.console-content-wrapper-closed {
    display: flex;
   // flex-direction: column;
   //padding-left: 1.75em;
    width: 75%;
    justify-content: center;
    //gap:.775em;
    text-decoration: none;
    //align-items: flex-end;
    align-self: center;
    padding-top: .8125em;
    padding-bottom: .8125em;
    border-radius: 25%;
   // background-color: red;

   transition: all .15s linear;
}
.console-content-wrapper-closed-alt {
    display: flex;
   // flex-direction: column;
   //padding-left: 1.75em;
    width: fit-content;
    justify-content: center;
    //gap:.775em;
    text-decoration: none;
    //align-items: flex-end;
    align-self: center;
    padding-top: .8125em;
    padding-bottom: .8125em;
    padding-left: .8125em;
    padding-right: .8125em;
    border-radius: 25%;
   // background-color: red;

   transition: all .15s linear;
}
.console-content-wrapper-closed-alt:hover {
    filter: brightness(1.25);
    background-color: #303030;
}
.console-content-wrapper {
    display: flex;
   // flex-direction: column;
   //padding-left: 1.75em;
    width: 77.5%;
    justify-content: flex-start;
    gap:.775em;
    
    align-items: flex-end;
    align-self: center;
    padding-top: .75em;
    padding-bottom: .75em;
    text-decoration: none;
    border-radius: 10px;
   // background-color: red;
   padding-left: .75em;
   padding-right: .75em;
   transition: all .15s linear;
}
.console-content-wrapper-active {
    display: flex;
    // flex-direction: column;
    //padding-left: 1.75em;
     width: 75%;
     justify-content: center;
     //gap:.775em;
     text-decoration: none;
     //align-items: flex-end;
     align-self: center;
     padding-top: .8125em;
     padding-bottom: .8125em;
     border-radius: 25%;
    // background-color: red;
     filter: brightness(1.25);
     background-color: #303030;
     transition: all .15s linear;
}
.console-content-wrapper-alt {
    display: flex;
   // flex-direction: column;
   //padding-left: 1.75em;
    width: 77.5%;
    justify-content: flex-start;
    gap:.775em;
    
    align-items: flex-end;
    align-self: center;
    padding-top: .75em;
    padding-bottom: .75em;
    text-decoration: none;
    border-radius: 10px;
   // background-color: red;
   padding-left: .75em;
   padding-right: .75em;
   transition: all .15s linear;
}
.console-content-wrapper:hover {
    filter: brightness(1.25);
    background-color: #252525dd;
}
.console-content-wrapper-closed:hover {
    filter: brightness(1.25);
    background-color: #292929;
}
.console-item-text {
    font-size: clamp(14px, 3vw, 14px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 800;
    white-space: nowrap;
    color:#6a6a6a;
   // padding-left: 1.875em;
   // line-height:38px;
   transform: translateY(.125em);
}
.first-subtext {
    padding-top: .125em;
}
.console-item-subtext {
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";// padding-left: .025em;
   // line-height: 20px;
  //  letter-spacing: .25px;
  //  align-self: flex-end;
   // line-height: 1.5em;
   user-select: none;
   -webkit-user-select: none; 
   -moz-user-select: none; 
   -ms-user-select: none;
   overflow: hidden;
    font-weight: 400;
    padding-top: .05em;
    color: #aaa;
}
.console-link-subtext {
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    overflow: hidden;
    font-size: clamp(10px, 3vw, 15px);
    font-family: "Rubik";// padding-left: .025em;
   // line-height: 20px;
  //  letter-spacing: .25px;
  //  align-self: flex-end;
   // line-height: 1.5em;
    font-weight: 400;
    padding-top: .075em;
    color: #757575;
}
.side-settings-subtext {
   // transform: translateY(-.025em);
}
.home-icon {
    width: 1.125em;
    height: 1.125em;
    align-self: center;
    color:#aaa;
}
.home-icon-closed {
    width: 1.3em;
    height: 1.3em;
    align-self: center;
    color:#aaa;
}
.console-settings-icon {
    width: 1.025em;
    height: 1.025em;
    align-self: center;
    color:#aaa;
}
.console-settings-icon-closed {
    width: 1.3em;
    height: 1.3em;
    align-self: center;
    color:#aaa;
}
.console-link-icon-closed {
    width: 1.3em;
    height: 1.3em;
    align-self: center;
    color:#aaa;
}
.console-link-icon {
    width: 1.125em;
    height: 1.125em;
    align-self: center;
    color:#757575;
}
.console-link-wrapper {
    display: flex;
    // flex-direction: column;
    //padding-left: 1.75em;
     width: 77.5%;
     justify-content: flex-start;
     gap:.65em;
     align-items: flex-end;
     position: absolute;
   //  transform: translateX(-.1em);
     align-self: center;
     bottom:4.25%;
}
.console-content-container-closed {
    display: flex;
    flex-direction: column;
    gap:.35em;
    min-width: 100%;
 //   padding-top: 2.6em;
    //background-color: red;
   padding-top: 1em;
  //  min-height:30%;
}
@media (max-width: 1146px) {
    .console-logo-text {
        display: none;
    }
    .console-app-logo {
        background-image: url('./assets/logo-alt.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        overflow: visible;
        width: 1.75em;
        height: 1.75em;
        filter: brightness(.9);
        transform: scaleX(1.05) translateY(.065em) translateX(-.25em);
        align-items: center;
    }
    .console-logo-text-container {
        display: flex;
        align-items: center;
        padding-top: .375em;
        text-decoration: none;
       // gap: .825em;
    
    //   padding-left: .65rem;
    }
    .console-link-wrapper {
        display: none;
    }
    .console-content-container {
        display: flex;
        flex-direction: column;
     //   gap:1.5em;
        min-width: 100%;
     //   padding-top: 2.6em;
       padding-top: 1.375em;
        min-height:30%;
    }
}
