p {
  z-index: 0;
}
.charity-page {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: hsla(0,0%,8%,.753);
   // overflow-x: hidden;
    overflow-y: scroll;
}
.header-blur {
  width:  100%;
  //left:.5%;

 // background-color: red;
  //background: linear-gradient(180deg,rgba(247, 77, 165, 0.6),rgba(238, 77, 93, 0));
//  height:13vh;
  //background-color: rgba(200, 79, 91, 0.8);
  transform: translateY(-1vh) ;
 // box-shadow: 0 0 20vh rgba(214, 72, 86, 0.85); 
  filter: blur(6px);
  position: absolute;
  top:0;

  left:0;
  border-bottom-right-radius: 30%;
  border-bottom-left-radius: 30%;
  z-index: -1;
  animation-name: animate-blur;
  animation-duration: .4s;
  animation-delay: .1s;
  animation-fill-mode: forwards;
  animation-timing-function:ease-out;
}
.blur-healthcare {
  background: linear-gradient(180deg,rgba(247, 77, 165, 0.6),rgba(238, 77, 93, 0));
}
.blur-environment {
  background: linear-gradient(180deg,rgba(90, 250, 207, 0.6),rgba(238, 77, 93, 0));
}
.blur-education {
  background: linear-gradient(180deg,rgba(230, 184, 93, 0.6),rgba(238, 77, 93, 0));
}
.blur-human {
  background: linear-gradient(180deg,rgba(90, 172, 255, 0.6),rgba(238, 77, 93, 0));
}
.blur-research {
  background: linear-gradient(180deg,rgba(124, 247, 239, 0.6),rgba(238, 77, 93, 0));
}
.blur-animals {
  background: linear-gradient(180deg,rgba(158, 241, 89, 0.6),rgba(238, 77, 93, 0));
}
.blur-community {
  background: linear-gradient(180deg,rgba(244, 199, 157, 0.6),rgba(238, 77, 93, 0));
}
@keyframes animate-blur {
  from {
      height: 0vh; 
  }
  to {   
      height: 13vh;
  }
}
.charity-page-container {
    min-height: 100vh;
    width: 90%;
    padding-top: 3.925em;
    padding-left: 10%;
  
    display: flex;
    flex-direction: column;
//    padding-left: .75%;
    overflow-x: hidden;
    padding-bottom: 3em;
  //  margin:0;
   // background-color: rgba(255, 0, 0, 0.213);
    align-self: flex-start
}
.manage-charity-container {
  max-width: 92.25%;
  height: fit-content;
  display: flex;
  padding-top: 2.75em;
 // background-color: rgba(46, 139, 86, 0.422);
}
.manage-charity-header-container {
  width: 100%;
  height: 100%;
  display: flex;
  //flex-direction: column;
  justify-content: flex-start;
// padding-right: 2.25em;
// align-items: center;
// background-color: rgba(117, 46, 139, 0.422);
  padding-bottom: 1em;
  border-bottom: 1px dashed #5a5a5a;
}
.charity-page-donate-button {
    width: 75%;
    height: 4%;
   // margin-top: .25em;
    border-radius: 20px;
    background-color: #eee;
    margin-left: auto;

    display: flex;
    justify-content: center;
    margin-right:.75em;
  //  transform: translateY(-.5em);
    align-self: flex-end;
   // margin-bottom: .25em;
}
.charity-page-donate-subcontainer {
  width:19%;
  align-self: center;
 //background-color: rgba(255, 0, 0, 0.096);
  display: flex;
  margin-left: auto;
  justify-content: center;
  gap:1.25em;
  height: fit-content;
}
.charity-page-like-icon {
  color:#eee;
  width:1.5em;
  height: 1.5em;
  transform: translateY(.05em);
}

.charity-page-like-icon-wrapper {
  min-width:20%;
  min-height: 25%;
  background-color: #5a5a5a;
  display: flex;
  transition: all .2s linear;
  transform: translateX(-.125em);
  justify-content: center;
  align-items: center;
  border-radius: 2em;
}
.charity-page-like-icon-wrapper:hover {
  filter: brightness(1.2);
}
.charity-page-like-icon-wrapper-alt {
  min-width:20%;
  transition: all .2s linear;
  min-height: 25%;
  transform: translateX(-.125em);
  background:linear-gradient(45deg, rgba(55, 88, 252, 0.6),#39ead599,#c9ff35ad);

 // background-color: rgb(190, 64, 106);
  display: flex;

  justify-content: center;
  align-items: center;
  border-radius: 2em;
}
.charity-page-like-icon-wrapper-alt:hover {

  filter: hue-rotate(30deg);
}
.charity-header-container {
    display: flex;
    flex-direction: row;
    
    width: 92.25%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
   //background-color: rgba(70, 131, 180, 0.274);
}
.charity-details-container {
    padding-top: .5em;
    width: 92.25%;
    height: 36vh;
   // overflow: hidden;
   // padding-left: .125em;
   // background-color: rgba(0, 139, 139, 0.228);
    display: flex;
  
}
.charity-statistics-wrapper {
    //width: 57.67%;
    width: 55%;
    display: flex;
    height: 86.5%;
   // margin-top: .25em;
 
    margin-left: auto;
    //background-color: rgba(46, 139, 86, 0.28);
}
.charity-donations-wrapper {
    height: 90%;
  //  background-color: rgba(255, 0, 0, 0.211);
    width: 40%;
   // margin-top: .25em;
    display: flex;
}
.charity-insights-wrapper {
    margin-top: 1.25em;
    width: 100%;
    border-radius: 20px;
   // background-color: rgba(70, 131, 180, 0.27);
    min-height:96.25%;

    //height:57.25%
}
.charity-page-donations-container {
    height: 100%;
  //  background-color: rgba(0, 255, 217, 0.211);
    width: 100%;  
    display: flex;
   // padding-left: .125em;
}
.charity-context-wrapper {
    min-width: 100%;
    //padding:1em;
    transform: translateX(-2em);
    height:fit-content;
    padding-top:1.75em;
    padding-right: 2em;
    padding-bottom: 1.5em;
    margin-top: 1.25em;
   // margin-right: 2em;
  //  outline:1px solid #303030;
    gap:1em;
    display: flex;
    align-items: flex-start;
    border-radius: 20px;
    background-color: #252525bb;
}
.charity-donations-bar-wrapper {
    height: 50%;
   // background-color: rgba(255, 0, 0, 0.211);
    width: 75%;

   // padding-right: 2em;
    z-index: 1;
    transform: translateX(-.65em);
  //  display: flex;
  //  padding-left: .125em;
 // background-color: #fff;
}
.charity-donations-legend {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    gap:3.125em;
    height: fit-content;

    margin-top: .875em;
    align-items: center;
    z-index: 1;
    transform: translateX(-1.5em);
    width: 50%;
    //background-color: rgba(255, 0, 0, 0.239);
}
.charity-legend-item-wrapper {
    display: flex;
    flex-direction: column;
    gap:1em;
    min-width: 100%;
}

.charity-legend-item {
    display: flex;
    justify-content: flex-end;
    gap: 1.5em;
    min-width: 100%;
    margin-left: auto;
    height: 5%;
  //  background-color: red;
    align-items: center;
    //padding-right: .75em;
}
.chart-details-text {
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color:#eee;
    line-height:22px;
    margin-left: auto;
    text-align: right;
    width: 70%;

}
.charity-legend-text {
    font-size: clamp(10px, 3vw, 14px);
    font-family: "Rubik";// padding-left: .025em;
   // line-height: 20px;
   white-space: nowrap;
    letter-spacing: -.25px;
    font-weight: 400;
    text-align: right;
    color: #eee;
  
}
.charity-legend-color {
    min-width: 1.3em;
    border-radius: 5px;

   // outline: 1px solid #959595;
    min-height: 1.3em;
    outline: 1px dashed #bbb;
  //  background: rgba(214, 72, 86, 0.8);
}
.charity-legend-healthcare {
  background: rgba(214, 72, 86, 0.8);
}
.charity-legend-environment {
  background: rgba(76, 184, 121, 0.8);
}
.charity-legend-education {
  background: rgba(230, 184, 93, 0.8);
}
.charity-legend-human {
  background: rgba(75, 130, 176, 0.8);
}
.charity-legend-research {
  background: rgba(65, 146, 140, 0.8);
}
.charity-legend-animals {
  background: rgba(142, 218, 80, 0.8);
}
.charity-legend-community {
  background: rgba(134, 109, 86, 0.8);
}

.charity-legend-color-avg {
    min-width: 1.3em;
    border-radius: 5px;
    outline: 1px dashed #bbb;
   // border:1px dashed rgba(214, 72, 86, 0.6);
   // outline: 1px solid #959595;
    min-height: 1.3em;
  //  background: rgba(214, 72, 86, 0.7);
    background: rgba(64, 64, 64, .8);
}


.charity-chart-empty {
    z-index: 2;
   // position: absolute;
    top:47.5%;
   position: absolute;
   z-index: 1;
   
}
.charity-polar-container {

    transform: translateY(-1.57em) translateX(3em) scale(.85);

}


.charity-insight-container {
    width: 94.5%;
    background-color: #252525bd;
    overflow-x: hidden;
    border-radius: 16px;
    padding-left:1.5em;
    display: flex;

    align-items: center;
  //  border:1px solid #303030;
    height: 100%;
   // outline:1px solid #303030;
    transition: all .15s linear;
  //  box-shadow: 3px 3px 2px 4px rgba(0,0,0,0.2);
  }
  .charity-insight-item-category {
    max-width: 100%;
    
   // padding-top: 3vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: rgba(255, 0, 0, 0.254);
    //padding-left: 1.5vw;
  //  padding-top:2vh;
  }
  .charity-insight-header-text {
    font-size:clamp(9px, 4vw, 14px);
    font-family: 'Rubik';
    font-weight:400;
    letter-spacing: .25px;
    z-index: 1;
    transform: scaleY(1.035) translateX(0em);
  }

  .charity-insight-item-figure {
    width: 100%;
    height: 35%;
  //  padding-left:1.5vw;
    align-items: center;
    display: flex;
  //  padding-bottom: .85vh;
    background-color: rgba(0, 255, 242, 0.254);
  }

/**/
/**/


  .charity-insight-figure-text {
    font-size:clamp(34px, 4vw, 40px);
    color:#eee;
    font-family: 'Inter';
    font-weight: 800;
    letter-spacing: -0.5px;
  }
  .charity-insight-item-name {
    max-width: 100%;
 
    background-color: rgba(77, 244, 174, 0.15);
   // min-height: max-content;
    display: flex;
    align-items: flex-start;
   // text-align: center;
   // padding-left: 1.35em;
    padding-right: 1.5em;
    justify-content: flex-start;
    padding-top: .675em;



    //border-top: 1px dashed #3e3e3e;
    padding-bottom:.5em;
  }
  .charity-insight-name-text {
    font-size:clamp(10px, 3vw, 16.5px);
    font-family: 'Inter';
    line-height: 26px;
    letter-spacing: -.25px;
    font-weight:600;
   // margin-left: auto;
    //margin-right: auto;
    align-self: flex-start;
    display: -webkit-box;
   // overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  //  padding-right: 5px;
    color:#efefef;
  }

  .charity-insight-type-container {
    height: fit-content;
    width: 100%;
    background-color: rgba(255, 251, 0, 0.174);
    display: flex;
  }
  .charity-insight-item-type-wrapper {
    width: fit-content;
    height: fit-content;
    padding-left: .75em;
    padding-right: .75em;
    padding-top: .07em;
    padding-bottom: .07em;
   // margin-left: 1.5em;
    margin-right: auto;
    display: flex;
    //transform:  translateX(-.25em) ;
    align-self: flex-start;
    justify-content: flex-start;
   // margin-bottom: .15em;
   // padding-bottom:.3%;
    border-radius:5px;
    
}
.charity-insight-item-type-text {
    font-size: clamp(10px, 3vw, 12.25px);
    color:#eee;
    font-family: 'Rubik';
   // letter-spacing: .1px;
    line-height: 24px;
    font-weight: 600;
}

.charity-chart-wrapper {
    height: fit-content;
 //   background-color: rgba(255, 0, 0, 0.211);
    width: 50%;
    // margin-top: .25em;
    display: flex;
    transform: translateY(-.175em);
 
}

.chart-container {
    padding: 1em;
    border-radius:20px ;
  //  background-color: #222222aa;
    position: relative;
    width: 70%;
    height: fit-content;
    transition: all .15s linear;
}
.charity-chart:hover {
  filter: contrast(1);
}
.bar-chart {
  filter: contrast(1.1);
}
.charity-chart {
    z-index: 2;
   // border-radius: 70%;

   // position: absolute;
    filter: contrast(1.05);
}
.charity-insight-figure-score {
    font-size:clamp(24px, 3vw, 34px);
    color:#eee;
    font-family: 'Inter';
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.25px;
    line-height: 44px;
}
.charity-chart-details-text {
    font-size: clamp(14px, 3vw, 14.5px);
    font-family: "Inter";
    letter-spacing: -.25px;
    font-weight: 600;
    color:#a0a0a0;
    z-index: 0;
    transition: all .15s linear;
    line-height:20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.charity-insights-subcontent {
    width: 35.5%;
   // padding-right: 1.5em;
    height: 100%;
 //   transform: translateY(-.125em);
  //  background-color: rgba(46, 139, 86, 0.145);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.charity-insights-subcontent-header {
    font-size: clamp(14px, 3vw, 16px);
    font-family: "Rubik";
    letter-spacing: -.25px;
    font-weight: 400;
    color:#eee;
    line-height:22px;
   // width: 70%;
}
.charity-insights-subcontent-text {
    font-size:clamp(24px, 3vw, 32px);
    color:#eee;
    font-family: 'Inter';
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.25px;
   // line-height: 44px;
}

.charity-details-container-low {
   // overflow: visible;
    overflow: hidden;
    width: 92.25%;
    height: 55vh;
   // padding-left: .125em;
   // background-color: rgba(0, 139, 139, 0.228);
    display: flex;
  
}
.charity-stats-container {
    width: 50%;
    height: 100%;
   // transform: translateY(-.025em);
    //padding-left: 1em;
   //background-color: rgba(46, 139, 86, 0.207);
    display: flex;
    transform: translateX(-.5em);
    flex-direction: column;
}

.charity-stats {
    width: 97%;
    height: 100%;
    //background-color: rgba(70, 131, 180, 0.159);
    display: flex;
    flex-direction: row;
  
     justify-content: space-between;
    //align-items: flex-start;
    padding-top: 1.6em;
    z-index: 1;
    

  }
  .excess-text {
    color:rgb(117, 182, 117);
  }
  .deficit-text {
    color:lightcoral;
  }
  .charity-stats-item-info {
    height: auto;
    width: fit-content;

  //  padding-top: .025em;
    display: flex;
   // padding-left: 1%;
    justify-content:center;
    flex-direction: column;
}
.charity-stat-col {
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    gap:2.5em;
    padding-left:.05em;
    
    padding-top: .25em;
    height:100%;
   // background-color: rgba(255, 0, 0, 0.244);
    align-items: flex-start;
}
.donate-page-link-button {
    text-decoration: none;
    min-height: 2.75em;
    display: flex;
    justify-content: center;
    align-items: center;

}
.charity-confirm-checkout-text {
    margin-left: auto;
    margin-right:auto;
    font-size: clamp(12px, 3vw, 17px);
    font-family: "Inter";
 //   transform: translateY(.025em);
    font-weight: 500;
 
    z-index: 1;

    background-color: transparent;
    //color:#eee;
    color:#0a0a0a;
}
.charity-favorite-icon {
    color:#656565;
    width:2.55%;
    height: 2.55%;
   // align-self: flex-start;
   // margin-right: 1em;
   opacity: 0;
    transform: translateY(10%) translateX(-30%); 
  }
  .location-insight-text {
   white-space: nowrap;
    align-self: flex-start;
  }
  .location-insight-wrapper {
    height: auto;
    width: fit-content;

  //  padding-top: .025em;
    display: flex;
   // padding-left: 1%;
    justify-content:flex-start;
    flex-direction: column;
}
.donation-item-type-text-alt {
  color:#0a0a0a;
  font-weight: 500;
}